import React, {
  useState,
  createContext,
  useContext,
  useRef,
  useEffect,
} from 'react';
import { configs } from '../Config/qlikConfig';
import { useParams, useLocation } from 'react-router-dom';
import {
  ALL_TRAFFIC,
  GQ_US,
  VOGUE_US,
  brandWithYearWiseSplit,
} from '../Utils/Constants';
import { DeferConnectEngineAndOpenDoc } from '../Hooks/useDeferConnectEngineAndOpenDoc';
import { getYearWiseAppConfig } from '../Utils/getYearWiseAppConfig';

const getDefaultBrand = (brandName) => {
  let default_site = ALL_TRAFFIC;
  switch (brandName) {
    case 'vogue':
      default_site = VOGUE_US;
      break;
    case 'gq':
      default_site = GQ_US;
      break;
    default:
      default_site = ALL_TRAFFIC;
  }
  return default_site;
};

const SessionContext = createContext();

export const SessionProvider = ({ props, children }) => {
  const params = useParams();
  const locationSite = useLocation();
  const siteRef = useRef();
  const [session, setQlikSession] = useState({
    call: (config) => {},
    close: null,
    error: null,
    handle: null,
    loading: true,
    suspend: null,
    unsuspend: null,
  });
  const [customFilters, setCustomFilters] = useState(0);
  const searchParams = new URLSearchParams(window.location.search);
  const hasHourName = JSON.parse(searchParams.get('filters'))?.Hour_Name;
  const [dateTimeFiltersApplied, setDateTimeFiltersApplied] =
    useState(hasHourName);

  const [app, setQlikApp] = useState({
    call: (...args) => {},
    error: null,
    handle: null,
    loading: false,
  });
  if (locationSite?.state?.site) {
    siteRef.current = locationSite.state.site;
  }

  const configFromUrl =
    params.app === 'app'
      ? configs[0]
      : brandWithYearWiseSplit.includes(params.app.toLowerCase())
      ? configs.find((item) => {
          return params.pubYear
            ? item.brand === params.app && getYearWiseAppConfig(item, params)
            : item.brand === params.app;
        })
      : configs.find((item) => item.brand === params.app);

  const brandName = configFromUrl ? configFromUrl.brand : 'global';
  const { accessToken, getAccessToken } = props;

  const defaultSite = siteRef.current
    ? siteRef.current
    : getDefaultBrand(configFromUrl.brand);
  let setProps = {
    session,
    app,
    brandName,
    defaultSite,
    setCustomFilters,
    customFilters,
    setDateTimeFiltersApplied,
    dateTimeFiltersApplied,
  };

  useEffect(() => {
    getAccessToken();
  }, [getAccessToken, brandName]);

  return (
    <>
      {accessToken && (
        <DeferConnectEngineAndOpenDoc
          configFromUrl={configFromUrl}
          accessToken={accessToken}
          setQlikSession={setQlikSession}
          setQlikApp={setQlikApp}
        />
      )}

      <SessionContext.Provider value={setProps}>
        {children}
      </SessionContext.Provider>
    </>
  );
};

export const useSession = () => useContext(SessionContext);
