import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getJsonDataPath } from '../Utils/jsonDataPath';
import { CACHED_S3_URL } from '../Utils/Constants';
import { useSession } from './SessionContext';

// Create Context
export const DataContext = createContext({
  filters: {}, // Default value
  brandName: '', // updating the context
});

// Create a Provider component
export const DataProvider = ({ children, value }) => {
  const { filters } = value;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [kpiCachedData, setKpiCachedData] = useState();
  // const [trendlineCachedData, setTrendlineCachedData] = useState();
  // const [top50CachedData, setTop50CachedData] = useState();
  const [cachedData, setCachedData] = useState({});
  const { brandName, customFilters, dateTimeFiltersApplied } = useSession();

  const getData = useCallback(
    ({ brandName, filters }) => {
      console.log("check cachedData", cachedData)
      try {
        const jsonDataPath = getJsonDataPath({
          brandName,
          filters,
        });
        const fetchKpiData = async () => {
          if (
            filters.tab === 'pages' &&
            (!cachedData || (cachedData && !cachedData[filters.view]))
          ) {
            console.log("fetching", cachedData)
            console.log((!cachedData || (cachedData && !cachedData[filters.view])))
            const response = await fetch(`${CACHED_S3_URL}${jsonDataPath}`, {
              mode: 'cors',
            });
            // if (!response.ok) {
            //   throw new Error('Failed to fetch data');
            // }
            const jsonData = await response.json();
            // setKpiCachedData({ [filters.view]: jsonData.kpi });
            // setTrendlineCachedData({ [filters.view]: jsonData.lineChart });
            // setTop50CachedData({ [filters.view]: jsonData.table });
            setCachedData({ ...cachedData, [filters.view]: jsonData });
          }
        };
        if (jsonDataPath && customFilters === 0 && !dateTimeFiltersApplied) {
          fetchKpiData();
        }
      } catch (error) {
        console.log('Error in fetching cached data', error);
      }
    },
    [cachedData, customFilters, dateTimeFiltersApplied]
  );

  useEffect(() => {
    if (brandName && filters) {
      getData({ brandName, filters });
    }
  }, [brandName, filters, getData]);

  return (
    <DataContext.Provider
      value={{
        data,
        loading,
        error,
        cachedData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
