export const hyperCubesConfig = {
  hourly: {
    pages: {
      kpi: [
        {
          render: true,
          name: '#New Posts',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=count( distinct {<content_id=,PublishDateHour={"<=$(=max(Hour))"}>}content_id)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyUV)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum({<hour={"<=$(=max(Hour))"}>}HourlyTS)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum({<hour={"<=$(=max(Hour))"}>}HourlyTS) / sum({<hour={"<=$(=max(Hour))"}>}HourlyVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
        
      ],
      lineChart: {
        render: true,
        name: 'line chart',
        hyperCubes: {
          dimensions: [{ qDef: { qFieldDefs: [`=UTCTime`] } }],
          measures: [
            {
              qDef: {
                qDef: `sum({<hour={"<=$(=max(Hour))"}>} $(=
                if(match(concat(distinct TrendMetric),'UniquePageviews'),'HourlyUPV' ,
                if(match(concat(distinct TrendMetric),'UniqueVisitors'),'HourlyUV',
                'HourlyTS' )
                ) ))
                `,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
          ],
          sort: null,
        },
      },
      donutChart: {
        render: true,
        name: 'donut Chart',
        hyperCubes: {
          dimensions: [{ qDef: { qFieldDefs: [`Market`] } }],
          measures: [
            {
              qDef: {
                qDef: `=sum({<hour={\"<=$(=max(Hour))\"}>}PageViews)`,
              },
            },
          ],
          sort: null,
        },
      },
      barChart: {
        render: true,
        name: 'bar chart',
        hyperCubes: {
          dimensions: [{ qDef: { qFieldDefs: [`=Brand`] } }],
          measures: [
            {
              qDef: {
                qDef: `=sum({<hour={"<=$(=max(Hour))"}>}PageViews)`,
              },
            },
          ],
          sort: null,
        },
      },
      table: {
        render: true,
        name: 'Top Stories',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `=if( aggr( Rank(Sum({<hour={"<=$(=max(Hour))"},URL=,Title=>} $(=
                  if(match(concat(distinct Metric),'UniquePageviews'),'HourlyUPV',
                  if(match(concat(distinct Metric),'UniqueVisitors'),'HourlyUV','HourlyTS'
                  ) )))
                  ,0,1),content_id) <=100,Title,null())
                  `,
                ],
              },
              qNullSuppression: true,
            },
            {
              qDef: {
                qFieldDefs: ['Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Market'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['URL'],
              },
            },
            {
              qDef: { qFieldDefs: ['=ContentType'] },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs: ['content_id'] },
            },
            {
              qDef: {
                qFieldDefs: ['Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ["=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)"],
              }
            },
            {
              qDef: {
                qFieldDefs: ["image_url"],
              }
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `sum({<hour={"<=$(=max(Hour))"}>} HourlyTS)`,
              },
              qSortBy: {
                qSortByNumeric: -1,
              },
            },
            {
              qDef: {
                qDef: "if(SyndicationFlag=1\r\n, concat( distinct Brand & ' - '&Market , ',')\n, concat(distinct {<FullUrlTarget-={'NA'}>} BrandTarget & ' - '& MarketTarget, ','))\n\n",
              }
            },
            {
              qDef:{
                qDef: `=text(num(max(aggr(Sum({<URL=,Title=>}HourlyUPV) /Sum(total <Title> {<URL=,Title=>}HourlyUPV), URL,Title, SubChannels)),'#.##%')& ' '
                & FirstSortedValue(SubChannels, -aggr(Sum({<URL=,Title=>}HourlyUPV)/Sum(total <Title> {<URL=,Title=>}HourlyUPV), URL,Title, SubChannels)))`
              }
            },
            
            {
              qDef: {
                qDef: `sum({<hour={"<=$(=max(Hour))"}>} HourlyUV)`,
              }
            },
            {
              qDef: {
                qDef: `sum({<hour={"<=$(=max(Hour))"}>} HourlyUPV)`,
              }
            },
            {
              qDef: {
                qDef: `=if(
                        match(concat(distinct Metric), 'UniquePageviews'),
                        sum({<hour={"<=$(=max(Hour))"}>} HourlyUPV),
                        if(
                          match(concat(distinct Metric), 'UniqueVisitors'),
                          sum({<hour={"<=$(=max(Hour))"}>} HourlyUV),
                          if(
                            match(concat(distinct Metric), 'TimeSpent'),
                          sum({<hour={"<=$(=max(Hour))"}>} HourlyTS),
                            sum({<hour={"<=$(=max(Hour))"}>} HourlyTS)
                          )
                        )
                      )`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            
          ],
          sortOrder: [17, 0],
        },
      },
    },
    contentType: {
      kpi: [
        {
          render: true,
          name: '#New Posts',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=count( distinct {<content_id=,PublishDateHour={"<=$(=max(Hour))"}>}content_id)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyUV)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum({<hour={"<=$(=max(Hour))"}>}HourlyTS)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum({<hour={"<=$(=max(Hour))"}>}HourlyTS) / sum({<hour={"<=$(=max(Hour))"}>}HourlyVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
        
      ],
      lineChart: {
        render: true,
        name: 'line chart',
        hyperCubes: {
          dimensions: [{ qDef: { qFieldDefs: [`UTCTime`] } }],
          measures: [
            {
              qDef: {
                qDef: `sum({<hour={"<=$(=max(Hour))"}>} $(=
                if(match(concat(distinct TrendMetric),'UniquePageviews'),'HourlyUPV' ,
                if(match(concat(distinct TrendMetric),'UniqueVisitors'),'HourlyUV',
                'HourlyTS' )
                ) ))`,
              },
            },
          ],
          sort: null,
        },
      },
      sectionTable: {
        render: true,
        name: 'Top Content Types',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=ContentType`],
                qSortCriterias: [
                  {
                    qSortByState: 1,
                    qSortByFrequency: 1,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 1,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=sum(HourlyTS)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(HourlyUV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(HourlyUPV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                sum(HourlyUPV),
                if(
                  match(concat(distinct Metric), 'UniqueVisitors'),
                  sum(HourlyUV),
                  if(
                    match(concat(distinct Metric), 'TimeSpent'),
                  sum(HourlyTS),
                    sum(HourlyTS)
                  )
                )
              )`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [4, 0],
        },
      },
      table: {
        render: true,
        name: 'Top Articles ',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `=if( aggr( Rank(Sum({<hour={"<=$(=max(Hour))"},URL=,Title=>} $(=
if(match(concat(distinct Metric),'UniquePageviews'),'HourlyUPV',
if(match(concat(distinct Metric),'UniqueVisitors'),'HourlyUV','HourlyTS'
) )))
,0,1),content_id) <=100,Title,null())
`,
                ],
              },
              qNullSuppression: true,
            },
            {
              qDef: {
                qFieldDefs: ['=Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Market'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['URL'],
              },
            },
            {
              qDef: { qFieldDefs: ['=ContentType'] },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs: ['content_id'] },
            },
            {
              qDef: {
                qFieldDefs: ['Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ["=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)"],
              }
            },
            {
              qDef: {
                qFieldDefs: ["image_url"],
              }
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=sum({<hour={"<=$(=max(Hour))"}>} $(=if(match(concat(distinct Metric),'UniquePageviews'),'HourlyUPV' ,
                if(match(concat(distinct Metric),'UniqueVisitors'),'HourlyUV','HourlyTS' ) ) ) )`,
              },
              qSortBy: {
                qSortByNumeric: -1,
              },
            },
            {
              qDef: {
                qDef: "if(SyndicationFlag=1\r\n, concat( distinct Brand & ' - '&Market , ',')\n, concat(distinct {<FullUrlTarget-={'NA'}>} BrandTarget & ' - '& MarketTarget, ','))\n\n",
              }
            },
            {
              qDef:{
                qDef: `=text(num(max(aggr(Sum({<URL=,Title=>}HourlyUPV) /Sum(total <Title> {<URL=,Title=>}HourlyUPV), Title, SubChannels)),'#.##%')& ' of UPV are from '
                & FirstSortedValue(SubChannels, -aggr(Sum({<URL=,Title=>}HourlyUPV)/Sum(total <Title> {<URL=,Title=>}HourlyUPV), Title, SubChannels)))`
              }
            }
          ],
          sort: null,
        },
      },
    },
    channel: {
      kpi: [
        {
          render: true,
          name: '#New Posts',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=count( distinct {<content_id=,PublishDateHour={"<=$(=max(Hour))"}>}content_id)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyUV)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum({<hour={"<=$(=max(Hour))"}>}HourlyTS)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum({<hour={"<=$(=max(Hour))"}>}HourlyTS) / sum({<hour={"<=$(=max(Hour))"}>}HourlyVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
      ],
      lineChart: {
        render: true,
        name: 'line chart',
        hyperCubes: {
          dimensions: [{ qDef: { qFieldDefs: [`UTCTime`] } }],
          measures: [
            {
              qDef: {
                qDef: `sum({<hour={"<=$(=max(Hour))"}>} $(=
                if(match(concat(distinct TrendMetric),'UniquePageviews'),'HourlyUPV' ,
                if(match(concat(distinct TrendMetric),'UniqueVisitors'),'HourlyUV',
                'HourlyTS' )
                ) ))
                `,
              },
            },
          ],
          sort: null,
        },
      },
      sectionTable: {
        render: true,
        name: 'Top Categories ',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=Category`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=sum(HourlyTS)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(HourlyUV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(HourlyUPV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                sum(HourlyUPV),
                if(
                  match(concat(distinct Metric), 'UniqueVisitors'),
                  sum(HourlyUV),
                  if(
                    match(concat(distinct Metric), 'TimeSpent'),
                  sum(HourlyTS),
                    sum(HourlyTS)
                  )
                )
              )`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [4, 0],
        },
      },
      table: {
        render: true,
        name: 'Top Articles',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `=if( aggr( Rank(Sum({<hour={"<=$(=max(Hour))"},URL=,Title=>} $(=
                  if(match(concat(distinct Metric),'UniquePageviews'),'HourlyUPV',
                  if(match(concat(distinct Metric),'UniqueVisitors'),'HourlyUV','HourlyTS'
                  ) )))
                  ,0,1),content_id) <=100,Title,null())
                  `,
                ],
              },
              qNullSuppression: true,
            },
            {
              qDef: {
                qFieldDefs: ['=Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Market'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['URL'],
              },
            },
            {
              qDef: { qFieldDefs: ['=ContentType'] },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs: ['content_id'] },
            },
            {
              qDef: {
                qFieldDefs: ['Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ["=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)"],
              }
            },
            {
              qDef: {
                qFieldDefs: ["image_url"],
              }
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=sum({<hour={"<=$(=max(Hour))"}>} $(=if(match(concat(distinct Metric),'UniquePageviews'),'HourlyUPV' ,
                if(match(concat(distinct Metric),'UniqueVisitors'),'HourlyUV','HourlyTS' ) ) ) )`,
              },
              qSortBy: {
                qSortByNumeric: -1,
              },
            },
            {
              qDef: {
                qDef: "if(SyndicationFlag=1\r\n, concat( distinct Brand & ' - '&Market , ',')\n, concat(distinct {<FullUrlTarget-={'NA'}>} BrandTarget & ' - '& MarketTarget, ','))\n\n",
              }
            },
            {
              qDef:{
                qDef: `=text(num(max(aggr(Sum({<URL=,Title=>}HourlyUPV) /Sum(total <Title> {<URL=,Title=>}HourlyUPV), Title, SubChannels)),'#.##%')& ' of UPV are from '
                & FirstSortedValue(SubChannels, -aggr(Sum({<URL=,Title=>}HourlyUPV)/Sum(total <Title> {<URL=,Title=>}HourlyUPV), Title, SubChannels)))`
              }
            }
          ],
          sort: null,
        },
      },
    },
    tag: {
      sectionTable: {
        render: true,
        name: 'Top Categories ',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=Tag`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=sum(HourlyTS)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(HourlyUV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(HourlyUPV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                sum(HourlyUPV),
                if(
                  match(concat(distinct Metric), 'UniqueVisitors'),
                  sum(HourlyUV),
                  if(
                    match(concat(distinct Metric), 'TimeSpent'),
                  sum(HourlyTS),
                    sum(HourlyTS)
                  )
                )
              )`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [4, 0],
        },
      },
    },
    
    trafficSource: {
      kpi: [
        {
          render: true,
          name: '#New Posts',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=count( distinct {<content_id=,PublishDateHour={"<=$(=max(Hour))"}>}content_id)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyUV)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum({<hour={"<=$(=max(Hour))"}>}HourlyTS)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum({<hour={"<=$(=max(Hour))"}>}HourlyTS) / sum({<hour={"<=$(=max(Hour))"}>}HourlyVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
      ],
      lineChart: {
        render: true,
        name: 'line chart',
        hyperCubes: {
          dimensions: [{ qDef: { qFieldDefs: [`UTCTime`] } }],
          measures: [
            {
              qDef: {
                qDef: `=sum({<hour={"<=$(=max(Hour))"},flag={'Hourly'}>} $(=
                if(match(concat(distinct ChannelTrendMetric),'UniquePageviews'),'HourlyUPV' ,
                if(match(concat(distinct ChannelTrendMetric),'UniqueVisitors'),'HourlyUV','HourlyTS')
                ) ))
                `,
              },
            },
          ],
          sort: null,
        },
      },
      sectionTable: {
        render: true,
        name: 'Top Channels ',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=Channels`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=sum(HourlyTS)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(HourlyUV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(HourlyUPV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                Sum(HourlyUPV),
                if(
                  match(concat(distinct Metric), 'UniqueVisitors'),
                  sum(HourlyUV),
                  if(
                    match(concat(distinct Metric), 'TimeSpent'),
                  sum(HourlyTS),
                    sum(HourlyTS)
                  )
                )
              )`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [4, 0],
        },
      },
      table: {
        render: true,
        name: 'Top Articles',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=if( aggr( Rank(Sum({<hour={"<=$(=max(Hour))"},URL=,Title=>} $(=
                  if(match(concat(distinct Metric),'UniquePageviews'),'HourlyUPV',
                  if(match(concat(distinct Metric),'UniqueVisitors'),'HourlyUV','HourlyTS'
                  ) )))
                  ,0,1),content_id) <=100,Title,null())
                  `],
              },
              "qNullSuppression": true,
            },
            {
              qDef: {
                qFieldDefs: ['=Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Market'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['URL'],
              },
            },
            {
              qDef: { qFieldDefs: ['=ContentType'] },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs: ['content_id'] },
            },
            {
              qDef: { qFieldDefs: ['=Channels'] },
            },
            {
              qDef: {
                qFieldDefs: ["=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)"],
              }
            },
            {
              qDef: {
                qFieldDefs: ["image_url"],
              }
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=sum({<hour={"<=$(=max(Hour))"}>} $(=if(match(concat(distinct Metric),'UniquePageviews'),'HourlyUPV' ,
                if(match(concat(distinct Metric),'UniqueVisitors'),'HourlyUV','HourlyTS' ) ) ) )`,
              },
              qSortBy: {
                qSortByNumeric: -1,
              },
            },
            {
              qDef: {
                qDef: "if(SyndicationFlag=1\r\n, concat( distinct Brand & ' - '&Market , ',')\n, concat(distinct {<FullUrlTarget-={'NA'}>} BrandTarget & ' - '& MarketTarget, ','))\n\n",
              }
            },
            {
              qDef:{
                qDef: `=text(num(max(aggr(Sum({<URL=,Title=>}HourlyUPV) /Sum(total <Title> {<URL=,Title=>}HourlyUPV), Title, SubChannels)),'#.##%')& ' of UPV are from '
                & FirstSortedValue(SubChannels, -aggr(Sum({<URL=,Title=>}HourlyUPV)/Sum(total <Title> {<URL=,Title=>}HourlyUPV), Title, SubChannels)))`
              }
            }
          ],
          sort: null,
        },
      },
    },
    author: {
      kpi: [
        {
          render: true,
          name: '#New Posts',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=count( distinct {<content_id=,PublishDateHour={"<=$(=max(Hour))"}>}content_id)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyUV)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum({<hour={"<=$(=max(Hour))"}>}HourlyTS)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum({<hour={"<=$(=max(Hour))"}>}HourlyTS) / sum({<hour={"<=$(=max(Hour))"}>}HourlyVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
      ],
      lineChart: {
        render: true,
        name: 'line chart',
        hyperCubes: {
          dimensions: [{ qDef: { qFieldDefs: [`UTCTime`] } }],
          measures: [
            {
              qDef: {
                qDef: `sum({<hour={"<=$(=max(Hour))"}>} $(=
                if(match(concat(distinct TrendMetric),'UniquePageviews'),'HourlyUPV' ,
                if(match(concat(distinct TrendMetric),'UniqueVisitors'),'HourlyUV',
                'HourlyTS' )
                ) ))
                `,
              },
            },
          ],
          sort: null,
        },
      },
      sectionTable: {
        render: true,
        name: 'Top Authors ',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=AuthorName`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `Sum(HourlyTS)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(HourlyUV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(HourlyUPV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                Sum(HourlyUPV),
                if(
                  match(concat(distinct Metric), 'UniqueVisitors'),
                  sum(HourlyUV),
                  if(
                    match(concat(distinct Metric), 'TimeSpent'),
                  sum(HourlyTS),
                    sum(HourlyTS)
                  )
                )
              )`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [4, 0],
        },
      },
      table: {
        render: true,
        name: 'table 1',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `=if( aggr( Rank(Sum({<hour={"<=$(=max(Hour))"},URL=,Title=>} $(=
                  if(match(concat(distinct Metric),'UniquePageviews'),'HourlyUPV',
                  if(match(concat(distinct Metric),'UniqueVisitors'),'HourlyUV','HourlyTS'
                  ) )))
                  ,0,1),content_id) <=100,Title,null())
                  `,
                ],
              },
              qNullSuppression: true,
            },
            {
              qDef: {
                qFieldDefs: ['=Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Market'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['URL'],
              },
            },
            {
              qDef: { qFieldDefs: ['ContentType'] },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['=AuthorName'] },
            },
            {
              qDef: {
                qFieldDefs: ['Category'],
              },
            },
            {
              qDef: { qFieldDefs: ['content_id'] },
            },
            {
              qDef: {
                qFieldDefs: ['=AuthorName'],
              },
            },
            {
              qDef: {
                qFieldDefs: ["=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)"],
              }
            },
            {
              qDef: {
                qFieldDefs: ["image_url"],
              }
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=sum({<hour={"<=$(=max(Hour))"}>} $(=if(match(concat(distinct Metric),'UniquePageviews'),'HourlyUPV' ,
                if(match(concat(distinct Metric),'UniqueVisitors'),'HourlyUV','HourlyTS' ) ) ) )`,
              },
              qSortBy: {
                qSortByNumeric: -1,
              },
            },
            {
              qDef: {
                qDef: "if(SyndicationFlag=1\r\n, concat( distinct Brand & ' - '&Market , ',')\n, concat(distinct {<FullUrlTarget-={'NA'}>} BrandTarget & ' - '& MarketTarget, ','))\n\n",
              }
            },
            {
              qDef:{
                qDef: `=text(num(max(aggr(Sum({<URL=,Title=>}HourlyUPV) /Sum(total <Title> {<URL=,Title=>}HourlyUPV), Title, SubChannels)),'#.##%')& ' of UPV are from '
                & FirstSortedValue(SubChannels, -aggr(Sum({<URL=,Title=>}HourlyUPV)/Sum(total <Title> {<URL=,Title=>}HourlyUPV), Title, SubChannels)))`
              }
            }
          ],
          sort: null,
        },
      },
    },
  },
  daily: {
    pages: {
      kpi: [
        {
          render: true,
          name: '#New Posts',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=Count({<Publishdate={">=$(=date(Min(Date)))<=$(=Date(Max(Date)))"},Date=,Month=,Paid_Organic=, Title=, URL=,flag=>} distinct content_id)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(UniquePageviews)`,
                },
              },
            ],
            sort: null,
          },
        },

        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(UniqueVisitors)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(TimeSpent)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact:true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum(TimeSpent) / sum(UniqueVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Audio Time Spent',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(AudioTS)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Video Time Spent',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(VideoTS)`,
                },
              },
            ],
            sort: null,
          },
        }
      ],
      lineChart: {
        render: true,
        name: 'line chart',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `Date`,
                ],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `sum({<flag={'Daily'},URL=,Title=>}$(=concat(distinct TrendMetric)))`,
              },
            },
          ],
          sort: null,
        },
      },
      table: {
        render: true,
        name: 'Top Stories',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  "= if( aggr( Rank( Sum({<URL=,Title=>}$(=concat(distinct Metric)) ),0,1),content_id) <=100,Title,null())",
                ],
              },
              qNullSuppression: true,
            },
            {
              qDef: {
                qFieldDefs: ['Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Market'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['URL'],
              },
            },
            {
              qDef: { qFieldDefs: ['ContentType'] },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['=AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs: ['content_id'] },
            },
            {
              qDef: {
                qFieldDefs: ['Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ["=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)"],
              }
            },
            {
              qDef: {
                qFieldDefs: ["image_url"],
              }
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `Sum({<URL=,Title=>} TimeSpent )`,
              },
              qSortBy: {
                qSortByNumeric: -1,
              },
            },
            {
              qDef: {
                qDef: "if(SyndicationFlag=1\r\n, concat( distinct Brand & ' - '&Market , ',')\n, concat(distinct {<FullUrlTarget-={'NA'}>} BrandTarget & ' - '& MarketTarget, ','))\n\n",
              }
            },
            {
              qDef: {
                qDef: `=text(num(max(aggr(Sum({<URL=,Title=>}ChannelUPV) /Sum(total <Title> {<URL=,Title=>}ChannelUPV), URL,Title, SubChannel) ) ,'#.##%')& ' '
                & FirstSortedValue(SubChannel, -aggr(Sum({<URL=,Title=>}ChannelUPV)/ Sum(total <Title> {<URL=,Title=>}ChannelUPV), URL,Title, SubChannel)))`
              }
            },
            {
              qDef: {
                qDef: `Sum({<URL=,Title=>} UniqueVisitors )`,
              },
            },
            {
              qDef: {
                qDef: `Sum({<URL=,Title=>} UniquePageviews )`,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                Sum({<URL=,Title=>} UniquePageviews ),
                if(
                  match(concat(distinct Metric), 'UniqueVisitors'),
                  Sum({<URL=,Title=>} UniqueVisitors ),
                  if(
                    match(concat(distinct Metric), 'TimeSpent'),
                    Sum({<URL=,Title=>} TimeSpent ),
                    Sum({<URL=,Title=>} TimeSpent )
                  )
                )
              )`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [17,0],
        },
      },
    },
    contentType: {
      kpi: [
        {
          render: true,
          name: '#New Posts',
          roundOf: true,
          exact: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=Count({<Publishdate={">=$(=date(Min(Date)))<=$(=Date(Max(Date)))"},Date=,Month=,Paid_Organic=, Title=, URL=,flag=>} distinct content_id)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(UniquePageviews)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(UniqueVisitors)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(TimeSpent)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum(TimeSpent) / sum(UniqueVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Audio Time Spent',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(AudioTS)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Video Time Spent',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(VideoTS)`,
                },
              },
            ],
            sort: null,
          },
        }
      ],
      lineChart: {
        render: true,
        name: 'line chart',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `Date`,
                ],
              },
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `sum({<flag={'Daily'},URL=,Title=>}$(=concat(distinct TrendMetric)))`,
              },
            },
          ],
          sort: null,
        },
      },
      sectionTable: {
        render: true,
        name: 'Top Content Types',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=ContentType`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=Sum(TimeSpent)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=Sum(UniqueVisitors)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(UniquePageviews)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                Sum({<URL=,Title=>}UniquePageviews),
                if(
                  match(concat(distinct Metric), 'UniqueVisitors'),
                  Sum({<URL=,Title=>}UniqueVisitors),
                  if(
                    match(concat(distinct Metric), 'TimeSpent'),
                  Sum({<URL=,Title=>}TimeSpent),
                    Sum({<URL=,Title=>}TimeSpent)
                  )
                )
              )`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [4, 0],
        },
      },
      table: {
        render: true,
        name: 'Top Articles ',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  "= if( aggr( Rank( Sum({<URL=,Title=>}$(=concat(distinct Metric)) ),0,1),content_id) <=100,Title,null())",
                ],
              },
              qNullSuppression: true,
            },
            {
              qDef: {
                qFieldDefs: ['=Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Market'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['URL'],
              },
            },
            {
              qDef: { qFieldDefs: ['=ContentType'] },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs: ['content_id'] },
            },
            {
              qDef: {
                qFieldDefs: ['Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ["=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)"],
              }
            },
            {
              qDef: {
                qFieldDefs: ["image_url"],
              }
            },
          ],
          measures: [
            {
              qDef: {
                qDef: '=Sum({<URL=,Title=>}$(=concat(distinct Metric)) )',
              },
              qSortBy: {
                qSortByNumeric: -1,
              },
            },
            {
              qDef: {
                qDef: "if(SyndicationFlag=1\r\n, concat( distinct Brand & ' - '&Market , ',')\n, concat(distinct {<FullUrlTarget-={'NA'}>} BrandTarget & ' - '& MarketTarget, ','))\n\n",
              }
            },
            {
              qDef: {
                qDef: `=text(num(max(aggr(Sum({<URL=,Title=>}ChannelUPV) /Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel) ) ,'#.##%')& ' '
                & FirstSortedValue(SubChannel, -aggr(Sum({<URL=,Title=>}ChannelPV)/ Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel)))`
              }
            }
          ],
          sort: null,
        },
      },
    },
    channel: {
      kpi: [
        {
          render: true,
          name: '#New Posts',
          roundOf: true,
          exact: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=Count({<Publishdate={">=$(=date(Min(Date)))<=$(=Date(Max(Date)))"},Date=,Month=,Paid_Organic=, Title=, URL=,flag=>} distinct content_id)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(UniquePageviews)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(UniqueVisitors)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(TimeSpent)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum(TimeSpent) / sum(UniqueVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Audio Time Spent',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(AudioTS)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Video Time Spent',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(VideoTS)`,
                },
              },
            ],
            sort: null,
          },
        }
      ],
      lineChart: {
        render: true,
        name: 'line chart',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `Date`,
                ],
              },
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `sum({<flag={'Daily'},URL=,Title=>}$(=concat(distinct TrendMetric)))`,
              },
            },
          ],
          sort: null,
        },
      },
      sectionTable: {
        render: true,
        name: 'Top Categories',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=Category`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=Sum(TimeSpent)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=Sum(UniqueVisitors)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(UniquePageviews)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                Sum({<URL=,Title=>}UniquePageviews),
                if(
                  match(concat(distinct Metric), 'UniqueVisitors'),
                  Sum({<URL=,Title=>}UniqueVisitors),
                  if(
                    match(concat(distinct Metric), 'TimeSpent'),
                  Sum({<URL=,Title=>}TimeSpent),
                    Sum({<URL=,Title=>}TimeSpent)
                  )))`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [4, 0],
        },
      },
      table: {
        render: true,
        name: 'Top Articles',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  "= if( aggr( Rank( Sum({<URL=,Title=>}$(=concat(distinct Metric)) ),0,1),content_id) <=100,Title,null())",
                ],
              },
              qNullSuppression: true,
            },
            {
              qDef: {
                qFieldDefs: ['=Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Market'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['URL'],
              },
            },
            {
              qDef: { qFieldDefs: ['ContentType'] },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs: ['content_id'] },
            },
            {
              qDef: {
                qFieldDefs: ['Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ["=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)"]
              }
            },
            {
              qDef: {
                qFieldDefs: ["image_url"],
              }
            },
          ],
          measures: [
            {
              qDef: {
                qDef: '=Sum({<URL=,Title=>}$(=concat(distinct Metric)) )',
              },
              qSortBy: {
                qSortByNumeric: -1,
              },
            },
            {
              qDef: {
                qDef: "if(SyndicationFlag=1\r\n, concat( distinct Brand & ' - '&Market , ',')\n, concat(distinct {<FullUrlTarget-={'NA'}>} BrandTarget & ' - '& MarketTarget, ','))\n\n",
              }
            },
            {
              qDef: {
                qDef: `=text(num(max(aggr(Sum({<URL=,Title=>}ChannelUPV) /Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel) ) ,'#.##%')& ' '
                & FirstSortedValue(SubChannel, -aggr(Sum({<URL=,Title=>}ChannelPV)/ Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel)))`
              }
            }
          ],
          sort: null,
        },
      },
    },
    tag: {
      sectionTable: {
        render: true,
        name: 'Top Tags',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=Tag`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=Sum(TimeSpent)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=Sum(UniqueVisitors)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(UniquePageviews)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                Sum({<URL=,Title=>}UniquePageviews),
                if(
                  match(concat(distinct Metric), 'UniqueVisitors'),
                  Sum({<URL=,Title=>}UniqueVisitors),
                  if(
                    match(concat(distinct Metric), 'TimeSpent'),
                  Sum({<URL=,Title=>}TimeSpent),
                    Sum({<URL=,Title=>}TimeSpent)
                  )))`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [4, 0],
        },
      },
    },
    trafficSource: {
      kpi: [
        {
          render: true,
          name: '#New Posts',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=Count({<Publishdate={">=$(=date(Min(Date)))<=$(=Date(Max(Date)))"},Date=,Month=,Paid_Organic=, Title=, URL=,flag=>} distinct content_id)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(ChannelUPV)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(ChannelUV)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(ChannelTS)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum(ChannelTS) / sum(ChannelUV))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
      ],
      lineChart: {
        render: true,
        name: 'line chart',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `Date`,
                ],
              },
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=Sum({<flag={'Daily'},URL=,Title=>} $(=
                if(match(concat(distinct ChannelTrendMetric),'UniquePageviews'),'ChannelUPV' ,
                if(match(concat(distinct ChannelTrendMetric),'UniqueVisitors'),'ChannelUV', 'ChannelTS')) ))`,
              },
            },
          ],
          sort: null,
        },
      },
      sectionTable: {
        render: true,
        name: 'Top Channels',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=Channel`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `Sum(ChannelTS)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `Sum(ChannelUV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(ChannelUPV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                Sum(ChannelUV),
                if(
                  match(concat(distinct Metric), 'UniqueVisitors'),
                  Sum(ChannelUV),
                  if(
                    match(concat(distinct Metric), 'TimeSpent'),
                  Sum(ChannelTS),
                    Sum(ChannelTS)
                  )
                ))`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [4, 0],
        },
      },
      table: {
        render: true,
        name: 'Top Articles',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `= if( aggr( Rank( Sum({<URL=,Title=>}$(=concat(distinct Metric)) ),0,1),content_id) <=100,Title,null())`,
                ],
              },
              qNullSuppression: true,
            },
            {
              qDef: {
                qFieldDefs: ['=Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Market'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['URL'],
              },
            },
            {
              qDef: { qFieldDefs: ['ContentType'] },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs: ['content_id'] },
            },
            {
              qDef: {
                qFieldDefs: ['Channel'],
              },
            },
            {
              qDef: {
                qFieldDefs: ["=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)"],
              }
            },
            {
              qDef: {
                qFieldDefs: ["image_url"],
              }
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=Sum({<URL=,Title=>}$(=concat(distinct Metric)) )`,
              },
              qSortBy: {
                qSortByNumeric: -1,
              },
            },
            {
              qDef: {
                qDef: "if(SyndicationFlag=1\r\n, concat( distinct Brand & ' - '&Market , ',')\n, concat(distinct {<FullUrlTarget-={'NA'}>} BrandTarget & ' - '& MarketTarget, ','))\n\n",
              }
            },
            {
              qDef: {
                qDef: `=text(num(max(aggr(Sum({<URL=,Title=>}ChannelUPV) /Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel) ) ,'#.##%')& ' '
                & FirstSortedValue(SubChannel, -aggr(Sum({<URL=,Title=>}ChannelPV)/ Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel)))`
              }
            }
          ],
          sort: null,
        },
      },
    },
    author: {
      kpi: [
        {
          render: true,
          name: '#New Posts',
          roundOf: true,
          exact: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=Count({<Publishdate={">=$(=date(Min(Date)))<=$(=Date(Max(Date)))"},Date=,Month=,Paid_Organic=, Title=, URL=,flag=>} distinct content_id)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(UniquePageviews)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(UniqueVisitors)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(TimeSpent)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum(TimeSpent) / sum(UniqueVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Audio Time Spent',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(AudioTS)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Video Time Spent',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(VideoTS)`,
                },
              },
            ],
            sort: null,
          },
        }
      ],
      lineChart: {
        render: true,
        name: 'line chart',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `Date`,
                ],
              },
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `sum({<flag={'Daily'},URL=,Title=>}$(=concat(distinct TrendMetric)))`,
              },
            },
          ],
          sort: null,
        },
      },
      sectionTable: {
        render: true,
        name: 'Top Authors',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=AuthorName`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `Sum(TimeSpent)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `Sum(UniqueVisitors)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(UniquePageviews)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                Sum(UniquePageviews),
                  if(
                    match(concat(distinct Metric), 'UniqueVisitors'),
                    Sum(UniqueVisitors),
                    if(
                      match(concat(distinct Metric), 'TimeSpent'),
                    Sum({<URL=,Title=>}TimeSpent),
                      Sum({<URL=,Title=>}TimeSpent)
                    )
                  )
                )`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [4, 0],
        },
      },
      table: {
        render: true,
        name: 'Top Articles',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  "= if( aggr( Rank( Sum({<URL=,Title=>}$(=concat(distinct Metric)) ),0,1),content_id) <=100,Title,null())",
                ],
              },
              qNullSuppression: true,
            },
            {
              qDef: {
                qFieldDefs: ['=Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Market'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['URL'],
              },
            },
            {
              qDef: { qFieldDefs: ['ContentType'] },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs: ['content_id'] },
            },
            {
              qDef: {
                qFieldDefs: ['Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ["=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)"],
              }
            },
            {
              qDef: {
                qFieldDefs: ["image_url"],
              }
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=Sum({<URL=,Title=>}$(=concat(distinct Metric)) )`,
              },
              qSortBy: {
                qSortByNumeric: -1,
              },
            },
            {
              qDef: {
                qDef: "if(SyndicationFlag=1\r\n, concat( distinct Brand & ' - '&Market , ',')\n, concat(distinct {<FullUrlTarget-={'NA'}>} BrandTarget & ' - '& MarketTarget, ','))\n\n",
              }
            },
            {
              qDef: {
                qDef: `=text(num(max(aggr(Sum({<URL=,Title=>}ChannelUPV) /Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel) ) ,'#.##%')& ' '
                & FirstSortedValue(SubChannel, -aggr(Sum({<URL=,Title=>}ChannelPV)/ Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel)))`
              }
            }
          ],
          sort: null,
        },
      },
    },
  },
  monthly: {
    pages: {
      kpi: [
        {
          render: true,
          name: '#New Posts',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=Count({<PublishMonth={'$(=Concat ( Distinct Month ,''','''))'},Date=,Month=,Paid_Organic=, Title=, URL=,flag=>} distinct content_id)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(UniquePageviews)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(UniqueVisitors)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(TimeSpent)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum(TimeSpent) / sum(UniqueVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Audio Time Spent',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(AudioTS)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Video Time Spent',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(VideoTS)`,
                },
              },
            ],
            sort: null,
          },
        }
      ],
      lineChart: {
        render: true,
        name: 'line chart',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `Month`,
                ],
              },
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `Sum({<flag={'Monthly'},URL=,Title=>}$(=concat(distinct TrendMetric)))`,
              },
            },
          ],
          sort: null,
        },
      },
      table: {
        render: true,
        name: 'Top Stories',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  "= if( aggr( Rank( Sum({<URL=,Title=>}$(=concat(distinct Metric)) ),0,1),content_id) <=100,Title,null())",
                ],
              },
              qNullSuppression: true,
            },
            {
              qDef: {
                qFieldDefs: ['Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Market'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['URL'],
              },
            },
            {
              qDef: { qFieldDefs: ['ContentType'] },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['=AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs: ['content_id'] },
            },
            {
              qDef: {
                qFieldDefs: ['Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ["=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)"],
              }
            },
            {
              qDef: {
                qFieldDefs: ["image_url"],
              }
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `Sum({<URL=,Title=>} TimeSpent )`,
              },
              qSortBy: {
                qSortByNumeric: -1,
              },
            },
            {
              qDef: {
                qDef: "if(SyndicationFlag=1\r\n, concat( distinct Brand & ' - '&Market , ',')\n, concat(distinct {<FullUrlTarget-={'NA'}>} BrandTarget & ' - '& MarketTarget, ','))\n\n",
              }
            },
            {
              qDef: {
                qDef: `=text(num(max(aggr(Sum({<URL=,Title=>}ChannelUPV) /Sum(total <Title> {<URL=,Title=>}ChannelUPV), URL,Title, SubChannel) ) ,'#.##%')& ' '
                & FirstSortedValue(SubChannel, -aggr(Sum({<URL=,Title=>}ChannelUPV)/ Sum(total <Title> {<URL=,Title=>}ChannelUPV), URL,Title, SubChannel)))`
              }
            },
            {
              qDef: {
                qDef: `Sum({<URL=,Title=>} UniqueVisitors )`,
              }
            },
            {
              qDef: {
                qDef: `Sum({<URL=,Title=>} UniquePageviews )`,
              }
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                Sum({<URL=,Title=>}UniquePageviews),
                if(
                  match(concat(distinct Metric), 'UniqueVisitors'),
                  Sum({<URL=,Title=>}UniqueVisitors),
                  if(
                    match(concat(distinct Metric), 'TimeSpent'),
                    Sum({<URL=,Title=>}TimeSpent),
                    Sum({<URL=,Title=>}TimeSpent)
                  )
                ))`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [17,0],
        },
      },
    },
    contentType: {
      kpi: [
        {
          render: true,
          name: '#New Posts',
          roundOf: true,
          exact: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=Count({<PublishMonth={'$(=Concat ( Distinct Month ,''','''))'},Date=,Month=,Paid_Organic=, Title=, URL=,flag=>} distinct content_id)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(UniquePageviews)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(UniqueVisitors)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(TimeSpent)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum(TimeSpent) / sum(UniqueVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Audio Time Spent',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(AudioTS)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Video Time Spent',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(VideoTS)`,
                },
              },
            ],
            sort: null,
          },
        }
      ],
      lineChart: {
        render: true,
        name: 'line chart',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `Month`,
                ],
              },
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `Sum({<flag={'Monthly'},URL=,Title=>}$(=concat(distinct TrendMetric)))`,
              },
            },
          ],
          sort: null,
        },
      },
      sectionTable: {
        render: true,
        name: 'Top Content Types',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=ContentType`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=sum(TimeSpent)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(UniqueVisitors)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=Sum(UniquePageviews)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                Sum({<URL=,Title=>}UniquePageviews),
                if(
                  match(concat(distinct Metric), 'UniqueVisitors'),
                  Sum({<URL=,Title=>}UniqueVisitors),
                  if(
                    match(concat(distinct Metric), 'TimeSpent'),
                  Sum({<URL=,Title=>}TimeSpent),
                    Sum({<URL=,Title=>}TimeSpent)
                  )
                ))`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [4, 0],
        },
      },
      table: {
        render: true,
        name: 'Top Articles ',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  "= if( aggr( Rank( Sum({<URL=,Title=>}$(=concat(distinct Metric)) ),0,1),content_id) <=100,Title,null())",
                ],
              },
              qNullSuppression: true,
            },
            {
              qDef: {
                qFieldDefs: ['=Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Market'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['URL'],
              },
            },
            {
              qDef: { qFieldDefs: ['=ContentType'] },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs: ['content_id'] },
            },
            {
              qDef: {
                qFieldDefs: ['Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ["=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)"],
              }
            },
            {
              qDef: {
                qFieldDefs: ["image_url"],
              }
            },
          ],
          measures: [
            {
              qDef: {
                qDef: '=Sum({<URL=,Title=>}$(=concat(distinct Metric)) )',
              },
              qSortBy: {
                qSortByNumeric: -1,
              },
            },
            {
              qDef: {
                qDef: "if(SyndicationFlag=1\r\n, concat( distinct Brand & ' - '&Market , ',')\n, concat(distinct {<FullUrlTarget-={'NA'}>} BrandTarget & ' - '& MarketTarget, ','))\n\n",
              }
            },
            {
              qDef: {
                qDef: `=text(num(max(aggr(Sum({<URL=,Title=>}ChannelUPV) /Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel) ) ,'#.##%')& ' '
                & FirstSortedValue(SubChannel, -aggr(Sum({<URL=,Title=>}ChannelPV)/ Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel)))`
              }
            }
          ],
          sort: null,
        },
      },
    },
    channel: {
      kpi: [
        {
          render: true,
          name: '#New Posts',
          roundOf: true,
          exact: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=Count({<PublishMonth={'$(=Concat ( Distinct Month ,''','''))'},Date=,Month=,Paid_Organic=, Title=, URL=,flag=>} distinct content_id)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(UniquePageviews)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(UniqueVisitors)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(TimeSpent)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum(TimeSpent) / sum(UniqueVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Audio Time Spent',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(AudioTS)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Video Time Spent',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(VideoTS)`,
                },
              },
            ],
            sort: null,
          },
        }
      ],
      lineChart: {
        render: true,
        name: 'line chart',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `Month`,
                ],
              },
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `Sum({<flag={'Monthly'},URL=,Title=>}$(=concat(distinct TrendMetric)))`,
              },
            },
          ],
          sort: null,
        },
      },
      sectionTable: {
        render: true,
        name: 'Top Categories',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=Category`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=Sum(TimeSpent)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=Sum(UniqueVisitors)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=Sum(UniquePageviews)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                Sum({<URL=,Title=>}UniquePageviews),
                if(
                  match(concat(distinct Metric), 'UniqueVisitors'),
                  Sum({<URL=,Title=>}UniqueVisitors),
                  if(
                    match(concat(distinct Metric), 'TimeSpent'),
                  Sum({<URL=,Title=>}TimeSpent),
                    Sum({<URL=,Title=>}TimeSpent)
                  )
                ))`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [4, 0],
        },
      },
      table: {
        render: true,
        name: 'Top Articles',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  "= if( aggr( Rank( Sum({<URL=,Title=>}$(=concat(distinct Metric)) ),0,1),content_id) <=100,Title,null())",
                ],
              },
              qNullSuppression: true,
            },
            {
              qDef: {
                qFieldDefs: ['=Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Market'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['URL'],
              },
            },
            {
              qDef: { qFieldDefs: ['ContentType'] },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs: ['content_id'] },
            },
            {
              qDef: {
                qFieldDefs: ['Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ["=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)"],
              }
            },
            {
              qDef: {
                qFieldDefs: ["image_url"],
              }
            },
          ],
          measures: [
            {
              qDef: {
                qDef: '=Sum({<URL=,Title=>}$(=concat(distinct Metric)) )',
              },
              qSortBy: {
                qSortByNumeric: -1,
              },
            },
            {
              qDef: {
                qDef: "if(SyndicationFlag=1\r\n, concat( distinct Brand & ' - '&Market , ',')\n, concat(distinct {<FullUrlTarget-={'NA'}>} BrandTarget & ' - '& MarketTarget, ','))\n\n",
              }
            },
            {
              qDef: {
                qDef: `=text(num(max(aggr(Sum({<URL=,Title=>}ChannelUPV) /Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel) ) ,'#.##%')& ' '
                & FirstSortedValue(SubChannel, -aggr(Sum({<URL=,Title=>}ChannelPV)/ Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel)))`
              }
            }
          ],
          sort: null,
        },
      },
    },
    tag: {
      sectionTable: {
        render: true,
        name: 'Top Tags',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=Tag`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=Sum(TimeSpent)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=Sum(UniqueVisitors)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=sum(UniquePageviews)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                Sum({<URL=,Title=>}UniquePageviews),
                if(
                  match(concat(distinct Metric), 'UniqueVisitors'),
                  Sum({<URL=,Title=>}UniqueVisitors),
                  if(
                    match(concat(distinct Metric), 'TimeSpent'),
                  Sum({<URL=,Title=>}TimeSpent),
                    Sum({<URL=,Title=>}TimeSpent)
                  )))`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [4, 0],
        },
      },
    },
    trafficSource: {
      kpi: [
        {
          render: true,
          name: '#New Posts',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=Count({<PublishMonth={'$(=Concat ( Distinct Month ,''','''))'},Date=,Month=,Paid_Organic=, Title=, URL=,flag=>} distinct content_id)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(ChannelUPV)`,
                },
              },
            ],
            sort: null,
          },
        },

        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(ChannelUV)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(ChannelTS)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum(ChannelTS) / sum(ChannelUV))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
      ],
      lineChart: {
        render: true,
        name: 'line chart',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `Month`,
                ],
              },
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=Sum({<flag={'Monthly'},URL=,Title=>} $(=
                if(match(concat(distinct ChannelTrendMetric),'UniquePageviews'),'ChannelUPV' ,
                if(match(concat(distinct ChannelTrendMetric),'UniqueVisitors'),'ChannelUV', 'ChannelTS')) ))
                `,
              },
            },
          ],
          sort: null,
        },
      },
      sectionTable: {
        render: true,
        name: 'Top Channels',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=Channel`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `Sum(ChannelTS)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `Sum(ChannelUV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `Sum(ChannelUPV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                Sum(ChannelUPV),
                  if(
                    match(concat(distinct Metric), 'UniqueVisitors'),
                    Sum(ChannelUV),
                    if(
                      match(concat(distinct Metric), 'TimeSpent'),
                    Sum(ChannelTS),
                      Sum(ChannelTS)
                    )
                  )
                )`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [4, 0],
        },
      },
      table: {
        render: true,
        name: 'Top Articles',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `= if( aggr( Rank( Sum({<URL=,Title=>}$(=concat(distinct Metric)) ),0,1),content_id) <=100,Title,null())`,
                ],
              },
              qNullSuppression: true,
            },
            {
              qDef: {
                qFieldDefs: ['=Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Market'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['URL'],
              },
            },
            {
              qDef: { qFieldDefs: ['ContentType'] },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs: ['content_id'] },
            },
            {
              qDef: {
                qFieldDefs: ['Channel'],
              },
            },
            {
              qDef: {
                qFieldDefs: ["=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)"],
              }
            },
            {
              qDef: {
                qFieldDefs: ["image_url"],
              }
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=Sum({<URL=,Title=>}$(=concat(distinct Metric)) )`,
              },
              qSortBy: {
                qSortByNumeric: -1,
              },
            },
            {
              qDef: {
                qDef: "if(SyndicationFlag=1\r\n, concat( distinct Brand & ' - '&Market , ',')\n, concat(distinct {<FullUrlTarget-={'NA'}>} BrandTarget & ' - '& MarketTarget, ','))\n\n",
              }
            },
            {
              qDef: {
                qDef: `=text(num(max(aggr(Sum({<URL=,Title=>}ChannelUPV) /Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel) ) ,'#.##%')& ' '
                & FirstSortedValue(SubChannel, -aggr(Sum({<URL=,Title=>}ChannelPV)/ Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel)))`
              }
            }
          ],
          sort: null,
        },
      },
    },
    author: {
      kpi: [
        {
          render: true,
          name: '#New Posts',
          roundOf: true,
          exact: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=Count({<PublishMonth={'$(=Concat ( Distinct Month ,''','''))'},Date=,Month=,Paid_Organic=, Title=, URL=,flag=>} distinct content_id)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(UniquePageviews)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(UniqueVisitors)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(TimeSpent)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum(TimeSpent) / sum(UniqueVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Audio Time Spent',
          roundOf: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: '=sum(AudioTS)',
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Video Time Spent',
          roundOf: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(VideoTS)`,
                },
              },
            ],
            sort: null,
          },
        }
      ],
      lineChart: {
        render: true,
        name: 'line chart',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  `Month`,
                ],
              },
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `Sum({<flag={'Monthly'},URL=,Title=>}$(=concat(distinct TrendMetric)))`,
              },
            },
          ],
          sort: null,
        },
      },
      sectionTable: {
        render: true,
        name: 'Top Author',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=AuthorName`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `Sum(TimeSpent)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `Sum(UniqueVisitors)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `Sum(UniquePageviews)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `=if(
                match(concat(distinct Metric), 'UniquePageviews'),
                Sum({<URL=,Title=>}UniquePageviews),
                  if(
                    match(concat(distinct Metric), 'UniqueVisitors'),
                    Sum({<URL=,Title=>}UniqueVisitors),
                    if(
                      match(concat(distinct Metric), 'TimeSpent'),
                    Sum({<URL=,Title=>}TimeSpent),
                      Sum({<URL=,Title=>}TimeSpent)
                    )
                  )
                )`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: { qv: '' },
                qSortByGreyness: 0,
              },
            },
          ],
          sortOrder: [4, 0],
        },
      },
      table: {
        render: true,
        name: 'Top Articles',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [
                  "= if( aggr( Rank( Sum({<URL=,Title=>}$(=concat(distinct Metric)) ),0,1),content_id) <=100,Title,null())",
                ],
              },
              qNullSuppression: true,
            },
            {
              qDef: {
                qFieldDefs: ['=Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Market'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['URL'],
              },
            },
            {
              qDef: { qFieldDefs: ['ContentType'] },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs: ['content_id'] },
            },
            {
              qDef: {
                qFieldDefs: ['Brand'],
              },
            },
            {
              qDef: {
                qFieldDefs: ["=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)"],
              }
            },
            {
              qDef: {
                qFieldDefs: ["image_url"],
              }
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=Sum({<URL=,Title=>}$(=concat(distinct Metric)) )`,
              },
              qSortBy: {
                qSortByNumeric: -1,
              },
            },
            {
              qDef: {
                qDef: "if(SyndicationFlag=1\r\n, concat( distinct Brand & ' - '&Market , ',')\n, concat(distinct {<FullUrlTarget-={'NA'}>} BrandTarget & ' - '& MarketTarget, ','))\n\n",
              }
            },
            {
              qDef: {
                qDef: `=text(num(max(aggr(Sum({<URL=,Title=>}ChannelUPV) /Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel) ) ,'#.##%')& ' '
                & FirstSortedValue(SubChannel, -aggr(Sum({<URL=,Title=>}ChannelPV)/ Sum(total <Title> {<URL=,Title=>}ChannelUPV), Title, SubChannel)))`
              }
            }
          ],
          sort: null,
        },
      },
    },
  },
  Filters: {
    pageViewFilter: {
      render: true,
      name: 'Pageview',
      hyperCubes: {
        dimensions: [{ qDef: { qFieldDefs: [`_Metric Name`] } }],
        measures: [],
        sort: null,
      },
    },
    ContentFilter: {
      render: true,
      name: 'Content',
      hyperCubes: {
        dimensions: [{ qDef: { qFieldDefs: [`=ContentType`] } }],
        measures: [],
        sort: null,
      },
    },
    tagsFilter: {
      render: true,
      name: 'Pageview',
      hyperCubes: {
        dimensions: [{ qDef: { qFieldDefs: [`=Category`] } }],
        measures: [],
        sort: null,
      },
    },
    ChannelFilter: {
      render: true,
      name: 'Channel',
      hyperCubes: {
        dimensions: [{ qDef: { qFieldDefs: [`	=Channel`] } }],
        measures: [],
        sort: null,
      },
    },
    authorFilter: {
      render: true,
      name: 'Authors',
      hyperCubes: {
        dimensions: [{ qDef: { qFieldDefs: [`=AuthorName`] } }],
        measures: [],
        sort: null,
      },
    },
    titleFilter: {
      render: true,
      name: 'Title',
      hyperCubes: {
        dimensions: [{ qDef: { qFieldDefs: [`=Title`] } }],
        measures: [],
        sort: null,
      },
    },
    audienceFilter: {
      render: true,
      name: 'Audience',
      hyperCubes: {
        dimensions: [{ qDef: { qFieldDefs: [`Paid_Organic`] } }],
        measures: [],
        sort: null,
      },
    },
    refreshDate: {
      render: true,
      name: 'Refresh',
      hyperCubes: {
        dimensions: [],
        measures: [
          {
            qDef: {
              qDef: `=Timestamp(max(MaxUS), 'DD MMM hh:mm TT')`,
            },
          },
        ],
        sort: null,
      },
    },
  },
  Appid: {
    render: true,
    name: 'Audience',
    hyperCubes: {
      dimensions: [
        {
          qDef: { qFieldDefs: [`Site`] },
          // qNullSuppression: true
        },
        {
          qDef: {
            qFieldDefs: [`Appid`],
          },
          // qNullSuppression: true,
        },
      ],
      measures: [],
      sort: null,
    },
  },
  storyView: {
    validFlags: {
      hyperCubes: {
        dimensions: [],
        measures: [
          {
            qDef: {
              qDef: 'content_id',
            },
          },
          {
            qDef: {
              qDef: `=concat({<flag=,Hour_Name=,Date=,Month=>} distinct flag,',')`,
            },
          }
        ],
      }
    },
    hourly: {
      info: {
        render: true,
        name: 'Story Info',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: ['Title'],
              },
              // "qNullSuppression": true,
            },
            {
              qDef: {
                qFieldDefs: ['ContentType'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Site'],
              },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs : ['CommerceFlag'] }
            },
            {
              qDef: { qFieldDefs : ['SyndicationFlag'] }
            },
            {
              qDef: { qFieldDefs : ['SharedFlag'] }
            },
            {
              qDef: { qFieldDefs : ['RevisedFlag'] }
            }
          ],
          measures: [],
          sort: null,
        },
      },
      kpi: [
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          exact: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          exact: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyUV)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          exact: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyTS)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum({<hour={"<=$(=max(Hour))"}>}HourlyTS) / sum({<hour={"<=$(=max(Hour))"}>}HourlyVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
        
      ],
      lineChart: {
        render: true,
        name: 'Page views vs TimeSpent',
        hyperCubes: {
          dimensions: [{ qDef: { qFieldDefs: [`=UTCTime`] } }],
          measures: [
            {
              qDef: {
                qDef: `sum({<hour={"<=$(=max(Hour))"}>} $(=
                if(match(concat(distinct TrendMetric),'UniquePageviews'),'HourlyUPV' ,
                if(match(concat(distinct TrendMetric),'UniqueVisitors'),'HourlyUV',
                'HourlyTS' )
                ) ))
                `,
              },
            },
            {
              qDef: {
                qDef: `Sum(TimeSpent)`,
              },
            },
          ],
          sort: null,
        },
      },
      ProgressBar: {
        render: true,
        name: 'Top Countries',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`=HourlyCountry`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `sum({<hour={"<=$(=max(Hour))"}>}HourlyUPV) / sum({<hour={"<=$(=max(Hour))"}>} TOTAL HourlyUPV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
          ],
          qInterColumnSortOrder: [2, 0, 1],
        },
      },
      donutDevice: {
        render: true,
        name: 'Top Devices by Page views ',
        hyperCubes: {
          dimensions: [{ qDef: { qFieldDefs: [`=HourlyDevice`] } }],
          measures: [
            {
              qDef: {
                qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
              },
            },
          ],
          sort: null,
        },
      },
      Channels: {
        Kpi: [
          {
            render: true,
            name: 'All Channels',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `Sum(ChannelUPV) / Sum(Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Direct ',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum({<Channels={'Direct'},hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Direct'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Email',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum({<Channels={'Email'},hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Email'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Google Discover',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum({<Channels={'Google Discover'},hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Google Discover'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Organic Search',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum({<Channels={'Organic Search'},hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Organic Search'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Referral Partners ',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum({<Channels={'Referral Partners'},hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Referral Partners'}>}ChannelUPV)/ SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Social ',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum({<Channels={'Social'},hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Social'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Other',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum({<Channels={'Other'},hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Other'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
        ],
        lineChart: {
          render: true,
          name: 'Top Channel by Pageviews Trend ',
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: [`=UTCTime`],
                  qSortCriterias: [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 0,
                      "qSortByAscii": 0,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 1,
                      "qExpression": {
                        "qv": "Timestamp (timestamp#(UTCTime,'MMM DD YYYY hh:mm TT'),'hh:mm TT')"
                      },
                      "qSortByGreyness": 0
                    }
                  ],
                },
              },
              {
                qDef: {
                  qFieldDefs: [`=Channels`],
                },
              },
            ],
            measures: [
              {
                qDef: {
                  qDef: `=sum({<hour={\"<=$(=max(Hour))\"}>}HourlyUPV)`,
                },
              },
              {
                qDef: {
                  qDef: `=sum({<Channels={'Direct'},hour={\"<=$(=max(Hour))\"}>}HourlyUPV)`,
                },
              },
              {
                qDef: {
                  qDef: `=sum({<Channels={'Email'},hour={\"<=$(=max(Hour))\"}>}HourlyUPV)`,
                },
              },
              {
                qDef: {
                  qDef: `=sum({<Channels={'Google Discover'},hour={\"<=$(=max(Hour))\"}>}HourlyUPV)`,
                },
              },
              {
                qDef: {
                  qDef: `=sum({<Channels={'Organic Search'},hour={\"<=$(=max(Hour))\"}>}HourlyUPV)`,
                },
              },
              {
                qDef: {
                  qDef: `=sum({<Channels={'Internal'},hour={\"<=$(=max(Hour))\"}>}HourlyUPV)`,
                },
              },
              {
                qDef: {
                  qDef: `=sum({<Channels={'Referral Partners'},hour={\"<=$(=max(Hour))\"}>}HourlyUPV)`,
                },
              },
              {
                qDef: {
                  qDef: `=sum({<Channels={'Social'},hour={\"<=$(=max(Hour))\"}>}HourlyUPV)`,
                },
              },
              {
                qDef: {
                  qDef: `=sum({<Channels={'Other'},hour={\"<=$(=max(Hour))\"}>}HourlyUPV)`,
                },
              },
            ],
            sort: null,
          },
        },
        table: {
          render: true,
          name: 'Top Channel by Page views Table ',
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: ['Channels'],
                  qSortCriterias: [
                    {
                      qSortByState: 0,
                      qSortByFrequency: 0,
                      qSortByNumeric: 1,
                      qSortByAscii: 1,
                      qSortByLoadOrder: 1,
                      qSortByExpression: 0,
                      qExpression: {
                        qv: '',
                      },
                      qSortByGreyness: 0,
                    },
                  ],
                },
                // qNullSuppression: true,
              },
              {
                qDef: {
                  qFieldDefs: ['SubChannels'],
                },
                // "qNullSuppression": true,
              },
            ],
            measures: [
              {
                qDef: {
                  qDef: `sum({<hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qDef: `=sum({<hour={"<=$(=max(Hour))"}>}HourlyUPV) / sum(Total {<hour={"<=$(=max(Hour))"}>}HourlyUPV)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
            ],
            qInterColumnSortOrder: [3, 0, 2, 1],
          },
        },
      },
      impact: {
        PublishHistory: {
          render: true,
          name: 'Publish History',
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: ['Brand'],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
              },
              {
                qDef: {
                  qFieldDefs: ['Market'],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
              },
              {
                qDef: {
                  qFieldDefs: [`=if ( RevisedFlag=1, date(RevisedDate,'MMM DD, YYYY'),null())`],
                  qSortCriterias: [
                    {
                      qSortByNumeric: -1,
                    }
                  ],
                },
              },
              {
                qDef: {
                  qFieldDefs: ['RevisionAuthor'],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
              },
              {
                qDef: {
                  qFieldDefs: [`WordCount`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
              },
            ],
            measures: [],
            sort: [0, 1, 2, 3, 4],
          },
        },
        Attribution: {
          render: true,
          name: 'Content Attribution',
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: [`=Monthname(Date)`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": -1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
              },
            ],
            measures: [
              {
                qDef: {
                  qLabel: "Unique Page Views",
                  qDef: `Sum({<Date=,Month=,hour=,Hour_Name=,flag={'Monthly'}>}UniquePageviews)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qLabel: "Trial Contribution Score",
                  qDef: `sum({<Date=,Month=,hour=,Hour_Name=,flag={'Monthly'}>}Contribution)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qLabel: "Last Touch Score",
                  qDef: `sum({<Date=,Month=,hour=,Hour_Name=,flag={'Monthly'}>}Score_Last_Touch)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qLabel: "Subscriptions",
                  qDef: `sum({<Date=,Month=,hour=,Hour_Name=,flag={'Monthly'}>}Subs)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },

            ],
            sort: [
              0,
              1,
              2,
              3
            ],
          }
        },
        SyndicationHistory: {
          render: true,
          name: 'Syndication History',
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: [`=if(SyndicationFlag=1\r\n, BrandSource & ' - ' & MarketSource\n, Brand & ' - '&Market)`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
                "qNullSuppression": true,
              },
              {
                qDef: {
                  qFieldDefs: [`=if(SyndicationFlag=1\r\n, TitleSource\n, Title)`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
                "qNullSuppression": true,
              },
              {
                qDef: {
                  qFieldDefs: [`=if(SyndicationFlag=1\r\n, FullUrlSource\n, URL)`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
                "qNullSuppression": true,
              },
              {
                qDef: {
                  qFieldDefs: [`=if(SyndicationFlag=1\r\n, Brand & ' - '&Market\n, BrandTarget & ' - '& MarketTarget)`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
                "qNullSuppression": true,
              },
              {
                qDef: {
                  qFieldDefs: [`=if(SyndicationFlag=1\r\n, Title\n, TitleTarget)`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
                "qNullSuppression": true,
              },
              {
                qDef: {
                  qFieldDefs: [`=if(SyndicationFlag=1\r\n, URL\n, FullUrlTarget)`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
                "qNullSuppression": true,
              },
            ],
            measures: [
              {
                qDef: {
                  qLabel: "PageViews",
                  qDef: `if(SyndicationFlag=1\r\n,\nif( match(GetFieldSelections(flag),'Daily') , Sum({<SynTrafficDate={\">=$(=Date(Min(Date)))<=$(=Date(max(Date)))\"}>}DailyReceivedPV) , \nSum({<SynTrafficMonth={\">=$(=Date(Min(Date)))<=$(=Date(max(Date)))\"}>}MonthlyReceivedPV))\n\n,\nif( match(GetFieldSelections(flag),'Daily') , Sum({<SharedSynTrafficDate={\">=$(=Date(Min(Date)))<=$(=Date(max(Date)))\"}>}DailySharedPV) , \nSum({<SharedSynTrafficMonth={\">=$(=Date(Min(Date)))<=$(=Date(max(Date)))\"}>}MonthlySharedPV))\n)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qLabel: "Unique Pageviews",
                  qDef: `if(SyndicationFlag=1\r\n, \nif( match(GetFieldSelections(flag),'Daily') , Sum({<SynTrafficDate={\">=$(=Date(Min(Date)))<=$(=Date(max(Date)))\"}>}DailyReceivedUPV) , \nSum({<SynTrafficMonth={\">=$(=Date(Min(Date)))<=$(=Date(max(Date)))\"}>}MonthlyReceivedUPV))\n,\nif( match(GetFieldSelections(flag),'Daily') , Sum({<SharedSynTrafficDate={\">=$(=Date(Min(Date)))<=$(=Date(max(Date)))\"}>}DailySharedUPV) , \nSum({<SharedSynTrafficMonth={\">=$(=Date(Min(Date)))<=$(=Date(max(Date)))\"}>}MonthlySharedUPV))\n)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qLabel: "Unique Visitors",
                  qDef: `if(SyndicationFlag=1\r\n,\nif( match(GetFieldSelections(flag),'Daily') , Sum({<SynTrafficDate={\">=$(=Date(Min(Date)))<=$(=Date(max(Date)))\"}>}DailyReceivedUV) , \nSum({<SynTrafficMonth={\">=$(=Date(Min(Date)))<=$(=Date(max(Date)))\"}>}MonthlyReceivedUV))\n,\nif( match(GetFieldSelections(flag),'Daily') , Sum({<SharedSynTrafficDate={\">=$(=Date(Min(Date)))<=$(=Date(max(Date)))\"}>}DailySharedUV) , \nSum({<SharedSynTrafficMonth={\">=$(=Date(Min(Date)))<=$(=Date(max(Date)))\"}>}MonthlySharedUV))\n)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },

            ],
            sort: [
              0,
              2,
              3,
              5,
              6,
              7,
              8,
              1,
              4
            ],
          }
        }
      },
      impactAffliate: {
        Kpi: [
          {
            render: true,
            name: 'Orders',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum([Number of Orders])`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Clicks',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum(Clicks)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'CVR',
            percent: true,
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum([Number of Orders]) / sum(Clicks)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Sales',
            hasCurrency: true,
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=Sum(Sales_USD)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Commissions',
            hasCurrency: true,
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=Sum(Commissions_USD)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'CPA',
            percent: true,
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `Sum(Commissions_USD)/Sum(Sales_USD)`,
                  },
                },
              ],
              sort: null,
            },
          },
        ],
        lineChart: {
          render: true,
          name: 'Affliate measures',
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: [`Date`],
                },
              },
            ],
            measures: [
              {
                qDef: {
                  qDef: `sum(Clicks)`,
                },
              },
              {
                qDef: {
                  qDef: `sum([Number of Orders])`,
                },
              },
              {
                qDef: {
                  qDef: `sum([Number of Orders]) / sum(Clicks)`,
                },
              },
              {
                qDef: {
                  qDef: `Sum(Sales_USD)`,
                },
              },
              {
                qDef: {
                  qDef: `Sum(Commissions_USD)`,
                },
              },
              {
                qDef: {
                  qDef: `Sum(Commissions_USD)/Sum(Sales_USD)`,
                },
              },
              {
                qDef: {
                  qDef: `Sum(Clicks) / Sum({<CommerceFlag={'Y'}>}UniqueVisitors)`,
                },
              },
            ],
            sort: null,
          },
        },
        topMerchants: {
          render: true,
          name: "Top Merchants",
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: ["Retailer"]
                }
              }
            ],
            measures: [
              {
                name: 'clicks',
                qDef: {
                  qDef: `=sum(Clicks)`,
                },
              },
              { name: 'clicks%',
                qDef: {
                  qDef: `=sum(Clicks) / Sum (Total Clicks)`,
                },
              },
              {
                name: 'orders',
                qDef: {
                  qDef: `=sum([Number of Orders])`,
                },
              },
              {
                name: 'orders%',
                qDef: {
                  qDef: `=sum([Number of Orders]) / sum(Total [Number of Orders])`,
                },
              },
              {
                name: 'cvr',
                percent: true,
                qDef: {
                  qDef: `=sum([Number of Orders]) / sum(Clicks)`,
                },
              },
              {
                name: 'sales',
                hasCurrency: true,
                qDef: {
                  qDef: `sum(Sales_USD)`,
                },
              },
              {
                name: 'sales%',
                hasCurrency: true,
                qDef: {
                  qDef: `sum(Sales_USD) / sum(Total Sales_USD)`,
                },
              },
              {
                name: 'commissions',
                hasCurrency: true,
                qDef: {
                  qDef: `Sum(Commissions_USD)`,
                },
              },
              {
                name: 'commissions%',
                hasCurrency: true,
                qDef: {
                  qDef: `Sum(Commissions_USD) / Sum(Total Commissions_USD)`,
                },
              },
              {
                name: 'cpa',
                percent: true,
                qDef: {
                  qDef: `Sum(Commissions_USD)/Sum(Sales_USD)`,
                },
              },
            ],
            sort: null
          }
        },
        topNetworks: {
          render: true,
          name: "Top Networks",
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: ["=Network"],
                },
              },
            ],
            measures: [
              {
                name: 'clicks',
                qDef: {
                  qDef: `=sum(Clicks)`,
                },
              },
              { name: 'clicks%',
                qDef: {
                  qDef: `=sum(Clicks) / Sum (Total Clicks)`,
                },
              },
              {
                name: 'orders',
                qDef: {
                  qDef: `=sum([Number of Orders])`,
                },
              },
              {
                name: 'orders%',
                qDef: {
                  qDef: `=sum([Number of Orders]) / sum(Total [Number of Orders])`,
                },
              },
              {
                name: 'cvr',
                percent: true,
                qDef: {
                  qDef: `=sum([Number of Orders]) / sum(Clicks)`,
                },
              },
              {
                name: 'sales',
                hasCurrency: true,
                qDef: {
                  qDef: `sum(Sales_USD)`,
                },
              },
              {
                name: 'sales%',
                hasCurrency: true,
                qDef: {
                  qDef: `sum(Sales_USD) / sum(Total Sales_USD)`,
                },
              },
              {
                name: 'commissions',
                hasCurrency: true,
                qDef: {
                  qDef: `Sum(Commissions_USD)`,
                },
              },
              {
                name: 'commissions%',
                hasCurrency: true,
                qDef: {
                  qDef: `Sum(Commissions_USD) / Sum(Total Commissions_USD)`,
                },
              },
              {
                name: 'cpa',
                percent: true,
                qDef: {
                  qDef: `Sum(Commissions_USD)/Sum(Sales_USD)`,
                },
              },
            ],
            sort: null
          }
        }
      },
    },
    daily: {
      info: {
        render: true,
        name: 'Story Info',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: ['Title'],
              },
              // "qNullSuppression": true,
            },
            {
              qDef: {
                qFieldDefs: ['ContentType'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Site'],
              },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
            {
              qDef: { qFieldDefs : ['CommerceFlag'] }
            },
            {
              qDef: { qFieldDefs : ['SyndicationFlag'] }
            },
            {
              qDef: { qFieldDefs : ['SharedFlag'] }
            },
            {
              qDef: { qFieldDefs : ['RevisedFlag'] }
            },
            {
              qDef: {
                qFieldDefs: ['URL']
              }
            },
            {
              qDef: {
                qFieldDefs: ['image_url'],
              },
            },

            {
              qDef:{
                qFieldDefs: ['content_id']
              }
            },

          ],
          measures: [
            {
              qDef: {
                qDef: `=if ( (SyndicationFlag=1 and sum({<Date=,Month>}DailyReceivedVisitors)>0 )
                  or (SharedFlag=1 and sum({<Date=,Month>}DailySharedVisitors)>0) ,1 ,0)`,
              },
            },
            {
              qDef: {
                qDef: `=if ( sum( TOTAL {<flag=>}distinct RevisedFlag) =1,1,0)`,
              },
            },
            {
              qDef: {
                qDef: `if ( sum({<Date=,Month=,hour=,Hour_Name=,flag={'Daily'}>}Contribution) >0,1,0)`,
              },
            },
          ],
          sort: null,
        },
      },
      kpi: [
        {
          render: true,
          name: 'Unique Page Views',
          roundOf: false,
          exact: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(UniquePageviews)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          roundOf: false,
          exact: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(UniqueVisitors)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          roundOf: false,
          exact: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(TimeSpent)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          roundOf: false,
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum(TimeSpent) / sum(UniqueVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Audio Time Spent',
          roundOf: false,
          exact: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(AudioTS)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Video Time Spent',
          roundOf: false,
          exact: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(VideoTS)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Logged In Users',
          roundOf: false,
          exact: false,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(LoggedinUsers)`,
                },
              },
            ],
            sort: null,
          },
        },
      ],
      lineChart: {
        render: true,
        name: 'Page views vs TimeSpent',
        hyperCubes: {
          dimensions: [{ qDef: { qFieldDefs: [`Date`] } }],
          measures: [
            {
              qDef: {
                qDef: `sum($(=concat(distinct TrendMetric)))`,
              },
            },
            {
              qDef: {
                qDef: `Sum(TimeSpent)`,
              },
            },
          ],
          sort: null,
        },
      },
      ProgressBar: {
        render: true,
        name: 'Top Countries',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: [`Country`],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
              qNullSuppression: true,
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `Sum(CountryUPV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
            {
              qDef: {
                qDef: `Sum(CountryUPV)/Sum(Total CountryUPV)`,
              },
              qSortBy: {
                qSortByState: 0,
                qSortByFrequency: 0,
                qSortByNumeric: -1,
                qSortByAscii: 0,
                qSortByLoadOrder: 1,
                qSortByExpression: 0,
                qExpression: {
                  qv: '',
                },
                qSortByGreyness: 0,
              },
            },
          ],
          qInterColumnSortOrder: [2, 0, 1],
        },
      },
      donutDevice: {
        render: true,
        name: 'Top Devices by Page views ',
        hyperCubes: {
          dimensions: [{ qDef: { qFieldDefs: [`Device`] } }],
          measures: [
            {
              qDef: {
                qDef: `Sum(DeviceUPV)`,
              },
            },
          ],
          sort: null,
        },
      },
      donutAudience: {
        render: true,
        name: 'Audience Breakdown ',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: ["=ValueList('Anonymous User','Logged in User')"],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `if( match( ValueList('Anonymous User','Logged in User'),'Anonymous User') , sum(UniqueVisitors) - sum (LoggedinUsers) , sum (LoggedinUsers))`,
              },
            },
          ],
          qInterColumnSortOrder: [0, 1],
        },
      },
      Channels: {
        Kpi: [
          {
            render: true,
            name: 'All Channels',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum(ChannelUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `Sum(ChannelUPV) / Sum(Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Direct ',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Direct'}>}ChannelUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Direct'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Email',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Email'}>}ChannelUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Email'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Google Discover',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Google Discover'}>}ChannelUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Google Discover'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Search',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Search'}>}ChannelUPV) `,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Search'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Internal ',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Internal'}>}ChannelUPV) `,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Internal'}>}ChannelUPV)/ SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Referral Partners ',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Referral Partners'}>}ChannelUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Referral Partners'}>}ChannelUPV)/ SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Social ',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Social'}>}ChannelUPV) `,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Social'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Others',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Others'}>}ChannelUPV) `,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Others'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
        ],
        lineChart: {
          render: true,
          name: 'Top Channel by Pageviews Trend ',
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: [`Date`],
                },
              },
              {
                qDef: {
                  qFieldDefs: [`Channel`],
                },
              },
            ],
            measures: [
              {
                qDef: {
                  qDef: `Sum(ChannelUPV)`,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Direct'}>}ChannelUPV) `,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Email'}>}ChannelUPV) `,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Google Discover'}>}ChannelUPV) `,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Search'}>}ChannelUPV) `,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Internal'}>}ChannelUPV) `,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Referral Partners'}>}ChannelUPV) `,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Social'}>}ChannelUPV) `,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Others'}>}ChannelUPV) `,
                },
              },
            ],
            sort: null,
          },
        },
        table: {
          render: true,
          name: 'Top Channel by Page views Table ',
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: ['Channel'],
                  qSortCriterias: [
                    {
                      qSortByState: 0,
                      qSortByFrequency: 0,
                      qSortByNumeric: 1,
                      qSortByAscii: 1,
                      qSortByLoadOrder: 1,
                      qSortByExpression: 0,
                      qExpression: {
                        qv: '',
                      },
                      qSortByGreyness: 0,
                    },
                  ],
                },
                // qNullSuppression: true,
              },
              {
                qDef: {
                  qFieldDefs: ['SubChannel'],
                },
                // "qNullSuppression": true,
              },
            ],
            measures: [
              {
                qDef: {
                  qDef: `Sum(ChannelUPV)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qDef: `Sum(ChannelUPV) / Sum ( Total ChannelUPV)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
            ],
            qInterColumnSortOrder: [3, 0, 2, 1],
          },
        },
      },
      impact: {
        PublishHistory: {
          render: true,
          name: 'Publish History',
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: ['Brand'],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
              },
              {
                qDef: {
                  qFieldDefs: ['Market'],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
              },
              {
                qDef: {
                  qFieldDefs: [`=if ( RevisedFlag=1, date(RevisedDate,'MMM DD, YYYY'),null())`],
                  qSortCriterias: [
                    {
                      qSortByNumeric: -1,
                    }
                  ],
                },
              },
              {
                qDef: {
                  qFieldDefs: ['RevisionAuthor'],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
              },
              {
                qDef: {
                  qFieldDefs: [`WordCount`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
              },
            ],
            measures: [],
            sort: [0, 1, 2, 3, 4],
          },
        },
        Attribution: {
          render: true,
          name: 'Content Attribution',
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: [`=Monthname(Date)`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": -1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
              },
            ],
            measures: [
              {
                qDef: {
                  qLabel: "Unique Page Views",
                  qDef: `Sum({<Date=,Month=,hour=,Hour_Name=,flag={'Monthly'}>}UniquePageviews)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qLabel: "Trial Contribution Score",
                  qDef: `sum({<Date=,Month=,hour=,Hour_Name=,flag={'Monthly'}>}Contribution)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qLabel: "Last Touch Score",
                  qDef: `sum({<Date=,Month=,hour=,Hour_Name=,flag={'Monthly'}>}Score_Last_Touch)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qLabel: "Subscriptions",
                  qDef: `sum({<Date=,Month=,hour=,Hour_Name=,flag={'Monthly'}>}Subs)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },

            ],
            sort: [
              0,
              1,
              2,
              3
            ],
          }
        },
        SyndicationHistory: {
          render: true,
          name: 'Syndication History',
          originalSite: {
            hyperCubes : {
              dimensions: [
                {
                  qDef: {
                    qFieldDefs: [`=if(SyndicationFlag=1, BrandSource & ' - ' & MarketSource, Brand & ' - '&Market)`],
                    "qSortCriterias": [
                      {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": { "qv": "" },
                        "qSortByGreyness": 0
                      }],
                  },
                  qNullSuppression: true,
                },
                {
                  qDef: {
                    qFieldDefs: [`=if(SyndicationFlag=1, FullUrlSource, URL)`],
                    "qSortCriterias": [
                      {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": { "qv": "" },
                        "qSortByGreyness": 0
                      }],
                  },
                  qNullSuppression: true,
                },
                {
                  qDef: {
                    qFieldDefs: [`=content_id`]
                  },
                  qNullSuppression: true,
                },
                {
                  qDef: {
                    qFieldDefs: [`=if(SyndicationFlag=1, TitleSource, Title)`]
                  },
                  qNullSuppression: true,
                },
              ],
              measures: [
                {
                  qDef: {
                    qLabel: "Original Pageviews",
                    qDef: `if(SyndicationFlag=1 and match(GetFieldSelections(flag),'Daily'), Sum({<SynTrafficDate={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}DailyReceivedPV) ,if(SyndicationFlag=1 and match(GetFieldSelections(flag),'Monthly'), Sum({<SynTrafficMonth={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}MonthlyReceivedPV), Sum(Pageviews)))`
                  }
                },
                {
                  qDef: {
                    qLabel: "Original Unique Pageviews",
                    qDef: `if(SyndicationFlag=1 and match(GetFieldSelections(flag),'Daily'), Sum({<SynTrafficDate={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}DailyReceivedUPV) ,if(SyndicationFlag=1 and match(GetFieldSelections(flag),'Monthly'), Sum({<SynTrafficMonth={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}MonthlyReceivedUPV), Sum(UniquePageviews)))`
                  }
                },
                {
                  qDef: {
                    qLabel: "Original Unique Visitors",
                    qDef: `if(SyndicationFlag=1 and match(GetFieldSelections(flag),'Daily'), Sum({<SynTrafficDate={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}DailyReceivedUV) ,if(SyndicationFlag=1 and match(GetFieldSelections(flag),'Monthly'), Sum({<SynTrafficMonth={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}MonthlyReceivedUV), Sum(UniqueVisitors)))`
                  }
                }
              ]
            }
          },
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: [`=if(SyndicationFlag=1, Brand & ' - '&Market, BrandTarget & ' - '& MarketTarget)`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
                "qNullSuppression": true,
              },
              {
                qDef: {
                  qFieldDefs: [`=if(SyndicationFlag=1, Title, if(TitleTarget<>'NA', TitleTarget))`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
                "qNullSuppression": true,
              },
              {
                qDef: {
                  qFieldDefs: [`=if(SyndicationFlag=1, URL, FullUrlTarget)`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
                "qNullSuppression": true,
              },
              {
                qDef: {
                  qFieldDefs: [`=content_id`]
                }
              }
            ],
            measures: [
              {
                qDef: {
                  qLabel: "PageViews",
                  qDef: `if(SyndicationFlag=1
                    , sum(Pageviews)
                    , if(match(GetFieldSelections(flag),'Daily')
                      , Sum({<SharedSynTrafficDate={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}DailySharedPV) 
                      , Sum({<SharedSynTrafficMonth={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}MonthlySharedPV)
                        )
                    )`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qLabel: "Unique Pageviews",
                  qDef: `if(SyndicationFlag=1
                    , sum(UniquePageviews)
                    , if(match(GetFieldSelections(flag),'Daily')
                      , Sum({<SharedSynTrafficDate={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}DailySharedUPV) 
                      , Sum({<SharedSynTrafficMonth={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}MonthlySharedUPV)
                        )
                    )`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qLabel: "Unique Visitors",
                  qDef: `if(SyndicationFlag=1
                    , sum(UniqueVisitors)
                    , if(match(GetFieldSelections(flag),'Daily')
                      , Sum({<SharedSynTrafficDate={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}DailySharedUV) 
                      , Sum({<SharedSynTrafficMonth={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}MonthlySharedUV)
                        )
                    )`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
            ],
            sort: [
              0,
              2,
              3,
              5,
              6,
              7,
              8,
              1,
              4
            ],
          }
        }
      },
      impactAffliate: {
        Kpi: [
          {
            render: true,
            name: 'Orders',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum([Number of Orders])`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Clicks',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum(Clicks)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'CVR',
            percent: true,
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum([Number of Orders]) / sum(Clicks)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Sales',
            hasCurrency: true,
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=Sum(Sales_USD)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Commissions',
            hasCurrency: true,
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=Sum(Commissions_USD)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'CPA',
            percent: true,
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `Sum(Commissions_USD)/Sum(Sales_USD)`,
                  },
                },
              ],
              sort: null,
            },
          },
        ],
        lineChart: {
          render: true,
          name: 'Affliate measures',
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: [`Date`],
                },
              },
            ],
            measures: [
              {
                qDef: {
                  qDef: `sum(Clicks)`,
                },
              },
              {
                qDef: {
                  qDef: `sum([Number of Orders])`,
                },
              },
              {
                qDef: {
                  qDef: `sum([Number of Orders]) / sum(Clicks)`,
                },
              },
              {
                qDef: {
                  qDef: `Sum(Sales_USD)`,
                },
              },
              {
                qDef: {
                  qDef: `Sum(Commissions_USD)`,
                },
              },
              {
                qDef: {
                  qDef: `Sum(Commissions_USD)/Sum(Sales_USD)`,
                },
              },
              {
                qDef: {
                  qDef: `Sum(Clicks) / Sum({<CommerceFlag={'Y'}>}UniqueVisitors)`,
                },
              },
            ],
            sort: null,
          },
        },
        topMerchants: {
          render: true,
          name: "Top Merchants",
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: ["Retailer"]
                }
              }
            ],
            measures: [
              {
                name: 'clicks',
                qDef: {
                  qDef: `=sum(Clicks)`,
                },
              },
              { name: 'clicks%',
                qDef: {
                  qDef: `=sum(Clicks) / Sum (Total Clicks)`,
                },
              },
              {
                name: 'orders',
                qDef: {
                  qDef: `=sum([Number of Orders])`,
                },
              },
              {
                name: 'orders%',
                qDef: {
                  qDef: `=sum([Number of Orders]) / sum(Total [Number of Orders])`,
                },
              },
              {
                name: 'cvr',
                percent: true,
                qDef: {
                  qDef: `=sum([Number of Orders]) / sum(Clicks)`,
                },
              },
              {
                name: 'sales',
                hasCurrency: true,
                qDef: {
                  qDef: `sum(Sales_USD)`,
                },
              },
              {
                name: 'sales%',
                hasCurrency: true,
                qDef: {
                  qDef: `sum(Sales_USD) / sum(Total Sales_USD)`,
                },
              },
              {
                name: 'commissions',
                hasCurrency: true,
                qDef: {
                  qDef: `Sum(Commissions_USD)`,
                },
              },
              {
                name: 'commissions%',
                hasCurrency: true,
                qDef: {
                  qDef: `Sum(Commissions_USD) / Sum(Total Commissions_USD)`,
                },
              },
              {
                name: 'cpa',
                percent: true,
                qDef: {
                  qDef: `Sum(Commissions_USD)/Sum(Sales_USD)`,
                },
              },
            ],
            sort: null
          }
        },
        topNetworks: {
          render: true,
          name: "Top Networks",
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: ["=Network"],
                },
              },
            ],
            measures: [
              {
                name: 'clicks',
                qDef: {
                  qDef: `=sum(Clicks)`,
                },
              },
              { name: 'clicks%',
                qDef: {
                  qDef: `=sum(Clicks) / Sum (Total Clicks)`,
                },
              },
              {
                name: 'orders',
                qDef: {
                  qDef: `=sum([Number of Orders])`,
                },
              },
              {
                name: 'orders%',
                qDef: {
                  qDef: `=sum([Number of Orders]) / sum(Total [Number of Orders])`,
                },
              },
              {
                name: 'cvr',
                percent: true,
                qDef: {
                  qDef: `=sum([Number of Orders]) / sum(Clicks)`,
                },
              },
              {
                name: 'sales',
                hasCurrency: true,
                qDef: {
                  qDef: `sum(Sales_USD)`,
                },
              },
              {
                name: 'sales%',
                hasCurrency: true,
                qDef: {
                  qDef: `sum(Sales_USD) / sum(Total Sales_USD)`,
                },
              },
              {
                name: 'commissions',
                hasCurrency: true,
                qDef: {
                  qDef: `Sum(Commissions_USD)`,
                },
              },
              {
                name: 'commissions%',
                hasCurrency: true,
                qDef: {
                  qDef: `Sum(Commissions_USD) / Sum(Total Commissions_USD)`,
                },
              },
              {
                name: 'cpa',
                percent: true,
                qDef: {
                  qDef: `Sum(Commissions_USD)/Sum(Sales_USD)`,
                },
              },
            ],
            sort: null
          }
        }
      },
    },
    monthly: {
      info: {
        render: true,
        name: 'Story Info',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: ['Title'],
              },
              // "qNullSuppression": true,
            },
            {
              qDef: {
                qFieldDefs: ['ContentType'],
              },
            },
            {
              qDef: {
                qFieldDefs: ['Site'],
              },
            },
            {
              qDef: { qFieldDefs: [`Publishdate`] },
            },
            {
              qDef: { qFieldDefs: ['AuthorName'] },
            },
            {
              qDef: { qFieldDefs: ['Category'] },
            },
          ],
          measures: [],
          sort: null,
        },
      },
      kpi: [
        {
          render: true,
          name: 'Unique Page Views',
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(UniquePageviews)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Unique Visitors',
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(UniqueVisitors)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Time Spent(mins)',
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(TimeSpent)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Avg Time Spent by UV',
          exact: true,
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=time((sum(TimeSpent) / sum(UniqueVisitors))/(60*24),'mm:ss')`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Audio Time Spent',
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(AudioTS)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Video Time Spent',
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(VideoTS)`,
                },
              },
            ],
            sort: null,
          },
        },
        {
          render: true,
          name: 'Logged In Users',
          hyperCubes: {
            dimensions: [],
            measures: [
              {
                qDef: {
                  qDef: `=sum(LoggedinUsers)`,
                },
              },
            ],
            sort: null,
          },
        },
      ],
      lineChart: {
        render: true,
        name: 'Page views vs TimeSpent',
        hyperCubes: {
          dimensions: [{ qDef: { qFieldDefs: [`Month`] } }],
          measures: [
            {
              qDef: {
                qDef: `sum($(=concat(distinct TrendMetric)))`,
              },
            },
            {
              qDef: {
                qDef: `Sum(TimeSpent)`,
              },
            },
          ],
          sort: null,
        },
      },
      ProgressBar: {
        render: true,
        name: 'Top Countries',
        hyperCubes: {
          dimensions: [
            {
              qDef: { qFieldDefs: [`Country`] },
              qNullSuppression: true
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `Sum(CountryUPV)`,
              },
            },
            {
              qDef: {
                qDef: `Sum(CountryUPV)/Sum(Total CountryUPV)`,
              },
            },
          ],
          sort: null,
        },
      },
      donutDevice: {
        render: true,
        name: 'Top Devices by Page views ',
        hyperCubes: {
          dimensions: [{ qDef: { qFieldDefs: [`Device`] } }],
          measures: [
            {
              qDef: {
                qDef: `Sum(DeviceUPV)`,
              },
            },
          ],
          sort: null,
        },
      },
      donutAudience: {
        name: 'Audience Breakdown ',
        hyperCubes: {
          dimensions: [
            {
              qDef: {
                qFieldDefs: ["=ValueList('Anonymous User','Logged in User')"],
                qSortCriterias: [
                  {
                    qSortByState: 0,
                    qSortByFrequency: 0,
                    qSortByNumeric: 1,
                    qSortByAscii: 1,
                    qSortByLoadOrder: 1,
                    qSortByExpression: 0,
                    qExpression: {
                      qv: '',
                    },
                    qSortByGreyness: 0,
                  },
                ],
              },
            },
          ],
          measures: [
            {
              qDef: {
                qDef: `if( match( ValueList('Anonymous User','Logged in User'),'Anonymous User') , sum(UniqueVisitors) - sum (LoggedinUsers) , sum (LoggedinUsers))`,
              },
            },
            // {
            //   qDef: {
            //     qDef: `sum(LoggedinUsers)`,
            //   },
            // },
          ],
          qInterColumnSortOrder: [0, 1],
        },
      },
      Channels: {
        Kpi: [
          {
            render: true,
            name: 'All Channels',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum(ChannelUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `Sum(ChannelUPV) / Sum(Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Direct ',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Direct'}>}ChannelUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Direct'}>}ChannelUPV) / sum( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Email',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Email'}>}ChannelUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Email'}>}ChannelUPV) / sum( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Google Discover',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Google Discover'}>}ChannelUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Google Discover'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Search',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Search'}>}ChannelUPV) `,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Search'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Internal ',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Internal'}>}ChannelUPV) `,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Internal'}>}ChannelUPV)/ SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Referral Partners ',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Referral Partners'}>}ChannelUPV)`,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Referral Partners'}>}ChannelUPV)/ SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Social ',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Social'}>}ChannelUPV) `,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Social'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Others',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `sum({<Channel={'Others'}>}ChannelUPV) `,
                  },
                },
                {
                  qDef: {
                    qDef: `sum({<Channel={'Others'}>}ChannelUPV) / SUm( Total ChannelUPV)`,
                  },
                },
              ],
              sort: null,
            },
          },
        ],
        lineChart: {
          render: true,
          name: 'Top Channel by Page views Trend ',
          hyperCubes: {
            dimensions: [
              { qDef: { qFieldDefs: [`Month`] } },
              { qDef: { qFieldDefs: [`Channel`] } },
            ],
            measures: [
              {
                qDef: {
                  qDef: `Sum(ChannelUPV)`,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Direct'}>}ChannelUPV) `,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Email'}>}ChannelUPV) `,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Google Discover'}>}ChannelUPV) `,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Search'}>}ChannelUPV) `,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Internal'}>}ChannelUPV) `,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Referral Partners'}>}ChannelUPV) `,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Social'}>}ChannelUPV) `,
                },
              },
              {
                qDef: {
                  qDef: `sum({<Channel={'Others'}>}ChannelUPV) `,
                },
              },
            ],
            sort: null,
          },
        },
        table: {
          render: true,
          name: 'Top Channel by Pageviews Table ',
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: ['SubChannel'],
                },
                qNullSuppression: true,
              },
              {
                qDef: {
                  qFieldDefs: ['Channel'],
                },
              },
            ],
            measures: [
              {
                qDef: {
                  qDef: `Sum(ChannelUPV)`,
                },
                qSortBy: {
                  qSortByNumeric: -1,
                },
              },
              {
                qDef: {
                  qDef: `Sum(ChannelUPV) / Sum ( Total ChannelUPV)`,
                },
                qSortBy: {
                  qSortByNumeric: -1,
                },
              },
            ],
            qInterColumnSortOrder: [3, 0, 2, 1],
          },
        },
      },
      impact: {
        PublishHistory: {
          render: true,
          name: 'Publish History',
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: ['Brand'],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
              },
              {
                qDef: {
                  qFieldDefs: ['Market'],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
              },
              {
                qDef: {
                  qFieldDefs: [`=if ( RevisedFlag=1, date(RevisedDate,'MMM DD, YYYY'),null())`],
                  qSortCriterias: [
                    {
                      qSortByNumeric: -1,
                    }
                  ],
                },
              },
              {
                qDef: {
                  qFieldDefs: ['RevisionAuthor'],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
              },
              {
                qDef: {
                  qFieldDefs: [`WordCount`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
              },
            ],
            measures: [],
            sort: [0, 1, 2, 3, 4],
          },
        },
        Attribution: {
          render: true,
          name: 'Content Attribution',
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: [`=Monthname(Date)`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": -1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
              },
            ],
            measures: [
              {
                qDef: {
                  qLabel: "Unique Page Views",
                  qDef: `Sum({<Date=,Month=,hour=,Hour_Name=,flag={'Monthly'}>}UniquePageviews)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qLabel: "Trial Contribution Score",
                  qDef: `sum({<Date=,Month=,hour=,Hour_Name=,flag={'Monthly'}>}Contribution)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qLabel: "Last Touch Score",
                  qDef: `sum({<Date=,Month=,hour=,Hour_Name=,flag={'Monthly'}>}Score_Last_Touch)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qLabel: "Subscriptions",
                  qDef: `sum({<Date=,Month=,hour=,Hour_Name=,flag={'Monthly'}>}Subs)`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },

            ],
            sort: [
              0,
              1,
              2,
              3
            ],
          }
        },
        SyndicationHistory: {
          render: true,
          name: 'Syndication History',
          originalSite: {
            hyperCubes : {
              dimensions: [
                {
                  qDef: {
                    qFieldDefs: [`=if(SyndicationFlag=1, BrandSource & ' - ' & MarketSource, Brand & ' - '&Market)`],
                    "qSortCriterias": [
                      {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": { "qv": "" },
                        "qSortByGreyness": 0
                      }],
                  },
                  qNullSuppression: true,
                },
                {
                  qDef: {
                    qFieldDefs: [`=if(SyndicationFlag=1, FullUrlSource, URL)`],
                    "qSortCriterias": [
                      {
                        "qSortByState": 0,
                        "qSortByFrequency": 0,
                        "qSortByNumeric": 1,
                        "qSortByAscii": 1,
                        "qSortByLoadOrder": 1,
                        "qSortByExpression": 0,
                        "qExpression": { "qv": "" },
                        "qSortByGreyness": 0
                      }],
                  },
                  qNullSuppression: true,
                },
                {
                  qDef: {
                    qFieldDefs: [`=content_id`]
                  },
                  qNullSuppression: true,
                },
                {
                  qDef: {
                    qFieldDefs: [`=if(SyndicationFlag=1, TitleSource, Title)`]
                  },
                  qNullSuppression: true,
                },
              ],
              measures: [
                {
                  qDef: {
                    qLabel: "Original Pageviews",
                    qDef: `if(SyndicationFlag=1 and match(GetFieldSelections(flag),'Daily'), Sum({<SynTrafficDate={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}DailyReceivedPV) ,if(SyndicationFlag=1 and match(GetFieldSelections(flag),'Monthly'), Sum({<SynTrafficMonth={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}MonthlyReceivedPV), Sum(Pageviews)))`
                  }
                },
                {
                  qDef: {
                    qLabel: "Original Unique Pageviews",
                    qDef: `if(SyndicationFlag=1 and match(GetFieldSelections(flag),'Daily'), Sum({<SynTrafficDate={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}DailyReceivedUPV) ,if(SyndicationFlag=1 and match(GetFieldSelections(flag),'Monthly'), Sum({<SynTrafficMonth={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}MonthlyReceivedUPV), Sum(UniquePageviews)))`
                  }
                },
                {
                  qDef: {
                    qLabel: "Original Unique Visitors",
                    qDef: `if(SyndicationFlag=1 and match(GetFieldSelections(flag),'Daily'), Sum({<SynTrafficDate={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}DailyReceivedUV) ,if(SyndicationFlag=1 and match(GetFieldSelections(flag),'Monthly'), Sum({<SynTrafficMonth={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}MonthlyReceivedUV), Sum(UniqueVisitors)))`
                  }
                }
              ]
            }
          },
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: [`=if(SyndicationFlag=1, Brand & ' - '&Market, BrandTarget & ' - '& MarketTarget)`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
                "qNullSuppression": true,
              },
              {
                qDef: {
                  qFieldDefs: [`=if(SyndicationFlag=1, Title, if(TitleTarget<>'NA', TitleTarget))`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
                "qNullSuppression": true,
              },
              {
                qDef: {
                  qFieldDefs: [`=if(SyndicationFlag=1, URL, FullUrlTarget)`],
                  "qSortCriterias": [
                    {
                      "qSortByState": 0,
                      "qSortByFrequency": 0,
                      "qSortByNumeric": 1,
                      "qSortByAscii": 1,
                      "qSortByLoadOrder": 1,
                      "qSortByExpression": 0,
                      "qExpression": { "qv": "" },
                      "qSortByGreyness": 0
                    }],
                },
                "qNullSuppression": true,
              },
              {
                qDef: {
                  qFieldDefs: [`=content_id`]
                }
              }
            ],
            measures: [
              {
                qDef: {
                  qLabel: "PageViews",
                  qDef: `if(SyndicationFlag=1
                    , sum(Pageviews)
                    , if(match(GetFieldSelections(flag),'Daily')
                      , Sum({<SharedSynTrafficDate={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}DailySharedPV) 
                      , Sum({<SharedSynTrafficMonth={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}MonthlySharedPV)
                        )
                    )`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qLabel: "Unique Pageviews",
                  qDef: `if(SyndicationFlag=1
                    , sum(UniquePageviews)
                    , if(match(GetFieldSelections(flag),'Daily')
                      , Sum({<SharedSynTrafficDate={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}DailySharedUPV) 
                      , Sum({<SharedSynTrafficMonth={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}MonthlySharedUPV)
                        )
                    )`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
              {
                qDef: {
                  qLabel: "Unique Visitors",
                  qDef: `if(SyndicationFlag=1
                    , sum(UniqueVisitors)
                    , if(match(GetFieldSelections(flag),'Daily')
                      , Sum({<SharedSynTrafficDate={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}DailySharedUV) 
                      , Sum({<SharedSynTrafficMonth={">=$(=Date(Min(Date)))<=$(=Date(max(Date)))"}>}MonthlySharedUV)
                        )
                    )`,
                },
                qSortBy: {
                  qSortByState: 0,
                  qSortByFrequency: 0,
                  qSortByNumeric: -1,
                  qSortByAscii: 0,
                  qSortByLoadOrder: 1,
                  qSortByExpression: 0,
                  qExpression: {
                    qv: '',
                  },
                  qSortByGreyness: 0,
                },
              },
            ],
            sort: [
              0,
              2,
              3,
              5,
              6,
              7,
              8,
              1,
              4
            ],
          }
        }
      },
      impactAffliate: {
        Kpi: [
          {
            render: true,
            name: 'Orders',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum([Number of Orders])`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Clicks',
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum(Clicks)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'CVR',
            percent: true,
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=sum([Number of Orders]) / sum(Clicks)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Sales',
            hasCurrency: true,
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=Sum(Sales_USD)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'Commissions',
            hasCurrency: true,
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `=Sum(Commissions_USD)`,
                  },
                },
              ],
              sort: null,
            },
          },
          {
            render: true,
            name: 'CPA',
            percent: true,
            hyperCubes: {
              dimensions: [],
              measures: [
                {
                  qDef: {
                    qDef: `Sum(Commissions_USD)/Sum(Sales_USD)`,
                  },
                },
              ],
              sort: null,
            },
          },
        ],
        lineChart: {
          render: true,
          name: 'Affliate measures',
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: [`=if( match(GetFieldSelections(flag),'Daily'), Date(Date), Monthname(Date))`],
                },
              },
            ],
            measures: [
              {
                qDef: {
                  qDef: `sum(Clicks)`,
                },
              },
              {
                qDef: {
                  qDef: `sum([Number of Orders])`,
                },
              },
              {
                qDef: {
                  qDef: `sum([Number of Orders]) / sum(Clicks)`,
                },
              },
              {
                qDef: {
                  qDef: `Sum(Sales_USD)`,
                },
              },
              {
                qDef: {
                  qDef: `Sum(Commissions_USD)`,
                },
              },
              {
                qDef: {
                  qDef: `Sum(Commissions_USD)/Sum(Sales_USD)`,
                },
              },
              {
                qDef: {
                  qDef: `Sum(Clicks) / Sum({<CommerceFlag={'Y'}>}UniqueVisitors)`,
                },
              },
            ],
            sort: null,
          },
        },
        topMerchants: {
          render: true,
          name: "Top Merchants ",
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: [
                    "Retailer"
                  ],
                },
              },
            ],
            measures: [
              {
                name: 'clicks',
                qDef: {
                  qDef: `=sum(Clicks)`,
                },
              },
              { name: 'clicks%',
                qDef: {
                  qDef: `=sum(Clicks) / Sum (Total Clicks)`,
                },
              },
              {
                name: 'orders',
                qDef: {
                  qDef: `=sum([Number of Orders])`,
                },
              },
              {
                name: 'orders%',
                qDef: {
                  qDef: `=sum([Number of Orders]) / sum(Total [Number of Orders])`,
                },
              },
              {
                name: 'cvr',
                percent: true,
                qDef: {
                  qDef: `=sum([Number of Orders]) / sum(Clicks)`,
                },
              },
              {
                name: 'sales',
                hasCurrency: true,
                qDef: {
                  qDef: `sum(Sales_USD)`,
                },
              },
              {
                name: 'sales%',
                hasCurrency: true,
                qDef: {
                  qDef: `sum(Sales_USD) / sum(Total Sales_USD)`,
                },
              },
              {
                name: 'commissions',
                hasCurrency: true,
                qDef: {
                  qDef: `Sum(Commissions_USD)`,
                },
              },
              {
                name: 'commissions%',
                hasCurrency: true,
                qDef: {
                  qDef: `Sum(Commissions_USD) / Sum(Total Commissions_USD)`,
                },
              },
              {
                name: 'cpa',
                percent: true,
                qDef: {
                  qDef: `Sum(Commissions_USD)/Sum(Sales_USD)`,
                },
              },
            ],
            sort: null,
          },
        },
        topNetworks: {
          render: true,
          name: "Top Networks",
          hyperCubes: {
            dimensions: [
              {
                qDef: {
                  qFieldDefs: ["=Network"],
                },
              },
            ],
            measures: [
              {
                name: 'clicks',
                qDef: {
                  qDef: `=sum(Clicks)`,
                },
              },
              { name: 'clicks%',
                qDef: {
                  qDef: `=sum(Clicks) / Sum (Total Clicks)`,
                },
              },
              {
                name: 'orders',
                qDef: {
                  qDef: `=sum([Number of Orders])`,
                },
              },
              {
                name: 'orders%',
                qDef: {
                  qDef: `=sum([Number of Orders]) / sum(Total [Number of Orders])`,
                },
              },
              {
                name: 'cvr',
                percent: true,
                qDef: {
                  qDef: `=sum([Number of Orders]) / sum(Clicks)`,
                },
              },
              {
                name: 'sales',
                hasCurrency: true,
                qDef: {
                  qDef: `sum(Sales_USD)`,
                },
              },
              {
                name: 'sales%',
                hasCurrency: true,
                qDef: {
                  qDef: `sum(Sales_USD) / sum(Total Sales_USD)`,
                },
              },
              {
                name: 'commissions',
                hasCurrency: true,
                qDef: {
                  qDef: `Sum(Commissions_USD)`,
                },
              },
              {
                name: 'commissions%',
                hasCurrency: true,
                qDef: {
                  qDef: `Sum(Commissions_USD) / Sum(Total Commissions_USD)`,
                },
              },
              {
                name: 'cpa',
                percent: true,
                qDef: {
                  qDef: `Sum(Commissions_USD)/Sum(Sales_USD)`,
                },
              },
            ],
            sort: null,
          }
        }
      }
    },
  },
};
